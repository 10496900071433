import paymentMethodApi from '@/api/payment-method'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  rkCurrencyId: 0,
  name: '',
  rkReasonId: 0,
  description: '',
  type: '',
  isCashRequired: false,
  icon: '',
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return paymentMethodApi.create(state)
      .then(({ data }) => commit('PAYMENT_METHOD_CREATED', data))
      .catch((error) => {
        commit('PAYMENT_METHOD_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    paymentMethodApi.getById(id)
      .then(({ data }) => commit('PAYMENT_METHOD_FETCHED', data))
      .catch(err => commit('PAYMENT_METHOD_FETCH_FAILED', err))
  },
  update({ commit }) {
    return paymentMethodApi.update(state)
      .then(({ data }) => commit('PAYMENT_METHOD_UPDATED', data))
      .catch((error) => {
        commit('PAYMENT_METHOD_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  PAYMENT_METHOD_CREATED() {
    store.dispatch('message/success', 'CONTACT.created')
    store.dispatch('paymentMethod/fetchList')
  },
  PAYMENT_METHOD_CREATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'CONTACT.failedCreate')
  },
  PAYMENT_METHOD_UPDATED() {
    store.dispatch('message/success', 'CONTACT.updated')
    store.dispatch('paymentMethod/fetchList')
  },
  PAYMENT_METHOD_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  PAYMENT_METHOD_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
