import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/user',
    name: 'user-list',
    component: () => import('@/views/user/user-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/order',
    name: 'order-list',
    component: () => import('@/views/order/order-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/vacancy',
    name: 'vacancy-list',
    component: () => import('@/views/vacancy/vacancy-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/feedback',
    name: 'feedback-list',
    component: () => import('@/views/feedback/feedback-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/city',
    name: 'city-list',
    component: () => import('@/views/city/city-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/contact',
    name: 'contact-list',
    component: () => import('@/views/contact/contact-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/group-criteria',
    name: 'group-criteria-list',
    component: () => import('@/views/group-criteria/group-criteria-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/restaurant',
    name: 'restaurant-list',
    component: () => import('@/views/restaurant/restaurant-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/order-type',
    name: 'order-type-list',
    component: () => import('@/views/order-type/order-type-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/role',
    name: 'role-list',
    component: () => import('@/views/role/role-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/customer',
    name: 'customer-list',
    component: () => import('@/views/customer/customer-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/payment-method',
    name: 'payment-method-list',
    component: () => import('@/views/payment-method/payment-method-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/digital-currency',
    name: 'digital-currency',
    component: () => import('@/views/report/order-digital-currency.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/push-notification',
    name: 'push-notification-list',
    component: () => import('@/views/push-notification/push-notification-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/promotion',
    name: 'promotion-list',
    component: () => import('@/views/promotion/promotion-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/promotion/add',
    name: 'promotion-add',
    component: () => import('@/views/promotion/promotion-add.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/promotion/edit/:id',
    name: 'promotion-edit',
    component: () => import('@/views/promotion/promotion-edit.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/custom-dish',
    name: 'present-dish-list',
    component: () => import('@/views/custom-dish/custom-dish-tab.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/custom-dish/add',
    name: 'present-dish-add',
    component: () => import('@/views/custom-dish/present-dish-add.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/custom-dish/edit/:id',
    name: 'present-dish-edit',
    component: () => import('@/views/custom-dish/present-dish-edit.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/custom-dish/add',
    name: 'appliance-dish-add',
    component: () => import('@/views/custom-dish/appliance-dish-add.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/custom-dish/edit/:id',
    name: 'appliance-dish-edit',
    component: () => import('@/views/custom-dish/appliance-dish-edit.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/role/edit/:id',
    name: 'role-edit',
    component: () => import('@/views/role/role-edit.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/city/delivery-area/:cityId',
    name: 'delivery-area',
    component: () => import('@/views/delivery-area/delivery-area-edit.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/city/street-list/:cityId',
    name: 'street-list',
    component: () => import('@/views/street/street-list.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/order-state',
    name: 'order-state',
    component: () => import('@/views/order-state/order-state-list.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/digital-currency-by-branch/:branchId/:start/:end',
    name: 'digital-currency-by-branch',
    component: () => import('@/views/report/order-digital-currency-by-branch.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/restaurant/branch-list/:restaurantId',
    name: 'branch-list',
    component: () => import('@/views/branch/branch-list.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/application-description',
    name: 'application-description-list',
    component: () => import('@/views/application-description/application-info-tab.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/counter-party',
    name: 'counter-party-list',
    component: () => import('@/views/counter-party/counter-party-list.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/menu-version',
    name: 'menu-version',
    component: () => import('@/views/menu-version/menu-version.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/menu-version/menu-category-list/:menuVersionId',
    name: 'menu-version-menu-category-list',
    component: () => import('@/views/menu-version/menu-category-list.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/menu-version/translations/:id',
    name: 'menu-version-translations',
    component: () => import('@/views/menu-version/menu-version-translation-form.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/menu-version/dish-form/:id',
    name: 'menu-version-dish-form',
    component: () => import('@/views/menu-version/dish-form.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/order/order-form-tab/:id',
    name: 'order-form-tab',
    component: () => import('@/views/order/order-form-tab.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/vacancy-application',
    name: 'vacancy-application-list',
    component: () => import('@/views/vacancy-application/vacancy-application-tab.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/vacancy-application/form/:id',
    name: 'vacancy-application-form',
    component: () => import('@/views/vacancy-application/vacancy-application-form.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/push-notification/history/:id',
    name: 'push-notification-history',
    component: () => import('@/views/push-notification/push-notification-history.vue'),
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    meta: {
      allowAnonymous: false,
      layout: 'content',
    },
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      allowAnonymous: true,
    },
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/account-settings/account-settings.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/system-settings',
    name: 'system-settings',
    component: () => import('@/views/system-settings/system-settings.vue'),
    meta: {
      layout: 'content',
      allowAnonymous: false,
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      allowAnonymous: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      allowAnonymous: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    meta: {
      allowAnonymous: true,
      layout: 'content',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  let accessToken = localStorage.getItem('access_token')
  if (accessToken === null && to.meta.allowAnonymous === false) {
    return next('login')
  }
  next()
})


export default router
