import systemSettingApi from '@/api/system-setting'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  port: '',
  host: '',
  terminalType: '',
  globalType: '',
  defaultCountryId: 0,
  sqlServerDataBaseConnectionString: '',
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return systemSettingApi.createCrm(state)
      .then(({ data }) => commit('CRM_SETTING_CREATED', data))
      .catch((error) => {
        commit('CRM_SETTING_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    systemSettingApi.getCrmById(id)
      .then(({ data }) => commit('CRM_SETTING_FETCHED', data))
      .catch(err => commit('CRM_SETTING_FETCH_FAILED', err))
  },
  update({ commit }) {
    return systemSettingApi.updateCrm(state)
      .then(({ data }) => commit('CRM_SETTING_UPDATED', data))
      .catch((error) => {
        commit('CRM_SETTING_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  CRM_SETTING_CREATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.created')
    store.dispatch('crmSettings/fetchList')
  },
  CRM_SETTING_CREATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedDealer')
  },
  CRM_SETTING_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('crmSettings/fetchList')
  },
  CRM_SETTING_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  CRM_SETTING_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
