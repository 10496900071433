import deliveryAreaApi from '@/api/delivery-area'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  latlngs: [],
  branches: [],
  color: '#7367F0',
  center: {
    lat: 42.882004,
    lng: 74.582748
  }
}

const getters = {}

const actions = {
  fetchForCity({ commit }, cityId) {
    deliveryAreaApi.getByCityId(cityId)
      .then(({ data }) => commit('SET_LIST', data))
  },
  update({ commit }, data) {
    return deliveryAreaApi.put(data)
      .then(() => commit('DELIVERY_AREA_UPDATED'))
      .catch((error) => {
        commit('DELIVERY_AREA_UPDATE_FAILED', { errors: error.response.data.errors })
        throw error
      })
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.latlngs = data.cityDeliveryArea.polygon === null ? [] : data.cityDeliveryArea.polygon
    state.color = data.cityDeliveryArea.color;
    state.center = data.cityDeliveryArea.center === null ? [42.882004, 74.582748] : data.cityDeliveryArea.center;
    state.branches = data.branches;
  },
  DELIVERY_AREA_UPDATED() {
    store.dispatch('message/success', 'DELIVERYAREA.success')
  },
  DELIVERY_AREA_UPDATE_FAILED() {
    store.dispatch('message/error', 'DELIVERYAREA.error')
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {}

const watchers = []

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
