import _ from 'lodash'
import pushNotificationApi from '@/api/push-notification'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    pushNotificationApi.getAll({...state.filters})
      .then(( {data} ) => commit('SET_LIST', data))
  },
  delete({ commit }, id) {
    return pushNotificationApi.delete(id)
      .then(() => commit('PUSH_NOTIFICATION_REMOVED', id))
      .catch((error) => {
        commit('PUSH_NOTIFICATION_REMOVE_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });

  },
  send({commit}){
    return pushNotificationApi.send()
      .then(() => commit('PUSH_NOTIFICATION_SENT', id))
      .catch((error) => {
        commit('PUSH_NOTIFICATION_SENT_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });
  }
}

const mutations = {
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  PUSH_NOTIFICATION_REMOVED(state, id) {
    store.dispatch('pushNotification/fetchList');
    store.dispatch('message/success', 'PUSH_NOTIFICATION.success')
  },
  PUSH_NOTIFICATION_SENT(state, id){
    store.dispatch('message/success', 'PUSH_NOTIFICATION.success')
  },
  PUSH_NOTIFICATION_REMOVE_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'PUSH_NOTIFICATION.removeFailure')
    state.errors = errors;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.pushNotification.filters, _.debounce(async () => await store.dispatch('pushNotification/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
