import _ from 'lodash'
import reportApi from '@/api/report'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  loading: false,
  filters: {
    dateRange: {
      start: null,
      end: null
    },
    branchId: null,
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false
  },
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    reportApi.getDigitalCurrencyByBranchOrders({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
  download({ commit }) {
    reportApi.getDigitalCurrencyByBranchOrdersFile({ ...state.filters })
      .then((response) => commit('DOWNLOAD_REPORT', response))
  },
}

const mutations = {
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  DOWNLOAD_REPORT(state, response) {
    const url = window.URL.createObjectURL(response.data)
    const link = document.createElement('a')
    let fileName = 'Отчет.xlsx'
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
}

const watchers = [
  [state => state.digitalCurrencyByBranch.filters, _.debounce(async () => await store.dispatch('digitalCurrencyByBranch/fetchList'), 250), {
    immediate: false,
    deep: true,
  }],
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
