import axios from '@/api/requests/axios'
import store from '@/store'
import usersApi from '@/api/user'

const state = {
  auth: {},
  data: {},
  errors: null,
}

const getters = {}

const storeTokens = ({ accessToken, refreshToken }) => {
  localStorage.setItem('access_token', accessToken)
  localStorage.setItem('refresh_token', refreshToken)
}

const storeUserData = (data) => {
  localStorage.setItem('userData', data)
}

const clearTokens = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

const actions = {
  signIn({ commit }, credentials) {
    return axios.post('api/admin/v1/authorization/login', credentials)
      .then(({ data }) => {
        commit('STORE_AUTH_TOKENS', data)
      })
      .catch(error => commit('AUTHENTICATION_FAIELD', error.response.data))
  },
  getUserData({ commit }) {
    return axios.get('api/admin/v1/users/data')
      .then(({ data }) => {
        commit('STORE_DATA', data)
      })
      .catch(error => commit('AUTHENTICATION_FAIELD', error.response.data))
  },
  logout({ commit }) {
    commit('SET_LOGOUT')
  },
  impersonateAs({ commit, dispatch }, id) {
    return usersApi.impersonateAs(id)
      .then(({ data }) => commit('STORE_IMPERSONATED_TOKEN', { access_token: data }))
      .then(() => dispatch('user/profile', null, { root: true }))
  },
}

const mutations = {
  STORE_IMPERSONATED_TOKEN(state, { access_token }) {
    sessionStorage.setItem('access_token', access_token)
    store.dispatch('message/success', 'successfully impersonated')
  },
  STORE_AUTH_TOKENS(state, { accessToken, refreshToken }) {
    state.auth = { accessToken, refreshToken }
    storeTokens(state.auth)
    store.dispatch('message/success', 'authentificated')
  },
  SET_LOGOUT(state) {
    state.auth = {}
    clearTokens()
  },
  AUTHENTICATION_FAIELD(state, data) {
    state.errors = data
  },
  CLEAR_ERRORS(state) {
    state.errors = null
  },
  STORE_DATA(state, data) {
    state.data = data
    storeUserData(JSON.stringify(data))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
