import orderTypeApi from '@/api/order-type'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  rk7OrderTypeCode: '',
  rk7OrderCategoryCode: '',
  rk7CurrencyCode: '',
  type: 0,
  minimumAmount: 0,
  cookingTime: null,
  deliveryTime: null,
  processingTime: null,
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return orderTypeApi.create(state)
      .then(({ data }) => commit('ORDER_TYPE_CREATED', data))
      .catch((error) => {
        commit('ORDER_TYPE_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    orderTypeApi.getById(id)
      .then(({ data }) => commit('ORDER_TYPE_FETCHED', data))
      .catch(err => commit('ORDER_TYPE_FETCH_FAILED', err))
  },
  update({ commit }) {
    return orderTypeApi.update(state)
      .then(({ data }) => commit('ORDER_TYPE_UPDATED', data))
      .catch((error) => {
        commit('ORDER_TYPE_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  ORDER_TYPE_CREATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.created')
    store.dispatch('orderType/fetchList')
  },
  ORDER_TYPE_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedDealer')
  },
  ORDER_TYPE_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('orderType/fetchList')
  },
  ORDER_TYPE_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  ORDER_TYPE_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
