import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`api/admin/v1/users`, { params }),
  create: (user) => axios.post('api/admin/v1/users', user),
  block: (id) => axios.put('api/admin/v1/users/block', id),
  activate: (id) => axios.put('api/admin/v1/users/activate', id),
  get: () => axios.get('api/admin/v1/users/account'),
  update: (account) => axios.put('api/admin/v1/users', account),
  updatePassword: (password) => axios.put('api/admin/v1/users/update-password', password)
}
