import _ from 'lodash'
import userApi from '@/api/user'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false
  }
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    userApi.getAll({...state.filters})
      .then(( {data} ) => commit('SET_LIST', data))
  },
  block({ commit }, id) {
    return userApi.block({id: id})
      .then(() => commit('USER_BLOCKED', id))
      .catch((error) => {
        commit('USER_BLOCK_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });
  },
  activate({ commit }, id) {
    return userApi.activate({id: id})
      .then(() => commit('USER_ACTIVATED', id))
      .catch((error) => {
        commit('USER_ACTIVATION_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });
  },
}

const mutations = {
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  USER_ACTIVATED(state, id) {
    store.dispatch('user/fetchList');
    store.dispatch('message/success', 'USER.success')
  },
  USER_ACTIVATION_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'USER.activationFailure')
    state.errors = errors;
  },
  USER_BLOCKED(state, id) {
    store.dispatch('user/fetchList');
    store.dispatch('message/success', 'USER.success')
  },
  USER_BLOCK_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'USER.blockFailure')
    state.errors = errors;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.user.filters, _.debounce(async () => await store.dispatch('user/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
