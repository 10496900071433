import orderApi from '@/api/order'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  guest: '',
  change: 0,
  branch: '',
  address: null,
  restaurant: '',
  rk7Id: '',
  sum: 0,
  paymentMethod: '',
  orderType: '',
  source: '',
  phoneNumber: '',
  orderDate: '',
  isCreatedInRk: false,
  dishes: [],
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchById({ commit }, id) {
    return orderApi.getById(id)
      .then(({ data }) => commit('ORDER_FETCHED', data))
      .catch((error) => {
        commit('ORDER_FETCH_FAILED', error.response.data);
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  ORDER_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ORDER_FETCH_FAILED() {
    store.dispatch('message/error', 'GROUP_CRITERIA.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
