export default {
  Chart: 'Chart',
  Promotions: 'Акции',
  LookUps: 'Справочники',
  Contacts: 'Контакты',
  ApplicationDescription: 'О приложении',
  Administration: 'Админинстрирование',
  Users: 'Пользователи',
  Actions: 'Действия',
  Name: 'Наименование',
  Reports: 'Отчеты',
  DigitalCurrency: 'Цифровые оплаты',
  Vacancies: 'Вакансии',
  Feedback: 'Обратная связь',
  Cities: 'Города',
  Add: 'Добавить',
  GroupCriteria: 'Группы для рассылки',
  PushNotification: 'Пуш уведомления',
  PaymentMethod: 'Способы оплаты',
  SystemSettings: 'Настройки системы',
  OrderTypes: 'Типы заказов',
  Dashboard: 'Главная',
  Roles: 'Роли',
  Customer: 'Клиенты',
  Restaurant: 'Рестораны',
  Menu: 'Меню',
  OrderState: 'Состояние заказа',
  CounterParty: 'Контрагенты',
  Orders: 'Заказы',
  VacancyApplication: 'Заявки на вакансию',
  ApplicationInfo: 'О приложении',
  TermsOfUse: 'Пользовательское соглашение',
  MobileApp: 'Мобильное приложение',
  WebSite: 'WEB Сайт',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  PhoneNumber: 'Телефонный номер',
  WhatsApp: 'WhatsApp',
  Android: 'Android',
  iOS: 'iOS',
  Delivery: 'Доставка',
  TakeAway: 'На вынос',
  Male: 'Мужской',
  Female: 'Женский',
  New: 'Новая',
  Rejected: 'Отказана',
  Processed: 'Обработана',
  Office: 'Офис',
  Cafe: 'Кафе',
  Manufacture: 'Производство',
  Documentation: 'Документация',
  PresentDish: 'Подарочный товар',
}
