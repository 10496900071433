<template>
  <v-list-group
    v-if='canViewVerticalNavMenuGroup(item)'
    ref='refVListGroup'
    class='vertical-nav-menu-group'
    :class="[{'vertical-nav-menu-group-active': isActive}, ...rootThemeClasses]"
    :value='isGroupExpanded'
    @click='updateGroupOpen(!isOpen)'
  >
    <template #prependIcon>
      <v-icon :class="{'alternate-icon-small': !item.icon}">
        {{ item.icon || alternateIcon }}
      </v-icon>
    </template>
    <template #activator>
      <v-list-item-title>{{ t(item.title) }}</v-list-item-title>

      <v-list-item-action
        v-if='item.badge'
        class='flex-shrink-0'
      >
        <v-badge
          :color='item.badgeColor'
          inline
          :content='item.badge'
        >
        </v-badge>
      </v-list-item-action>
    </template>

    <component
      :is='resolveNavItemComponent(child)'
      v-for='child in item.children'
      v-if='isTabAvailable(child)'
      :key='child.subheader || child.title'
      :item='child'
    ></component>
  </v-list-group>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, inject, ref, watch } from '@vue/composition-api'

// SFCs
import VerticalNavMenuSectionTitle
  from '../vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import useVerticalNavMenu from '../../../../composable/vertical-nav/useVerticalNavMenu'

// Composable
import useNav from '../../../../../layouts/composable/useNav'
import useAppConfig from '../../../../../app-config/useAppConfig'
import useVerticalNavGroup from '../../../../composable/vertical-nav/useVerticalNavGroup'
import { useUtils } from '../../../../../libs/i18n'
import { useUtils as useAclUtils } from '../../../../../libs/acl'

// Other
import themeConfig from '../../../../../../../themeConfig'
import { mapState } from 'vuex'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isTabAvailable(navItem) {
      if (navItem.children) {
        return navItem.children.some(c => c.permission === undefined || c.permission === null || this.getUserPermissions.indexOf(c.permission) > -1)
      } else {
        return navItem.permission === undefined || navItem.permission === null ? true : this.getUserPermissions.indexOf(navItem.permission) > -1
      }
    },
  },
  computed: {
    getUserPermissions() {
      let data = JSON.parse(localStorage.getItem('userData'))
      if (data === undefined || data === null) {
        return this.userData.permissions
      }
      return data.permissions
    },
    ...mapState('auth', {
      userData: 'data',
    }),
    rootThemeClasses() {
      return {
        'theme--dark': this.$vuetify.theme.isDark,
        'theme--light': !this.$vuetify.theme.isDark,
      }
    },

  },
  setup(props) {
    const { resolveNavItemComponent } = useVerticalNavMenu()
    const { isOpen, updateGroupOpen, isActive } = useVerticalNavGroup(props.item)
    const { canViewVerticalNavMenuGroup } = useAclUtils()
    const { isNavGroupActive } = useNav()
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    const isAccordion = themeConfig.menu.verticalMenuAccordion

    // Template ref
    const refVListGroup = ref(null)

    // VNavigationDrawer Internal API
    const isGroupExpanded = computed({
      get: () => (refVListGroup.value ? refVListGroup.value.isActive : false),
      set: value => {
        refVListGroup.value.isActive = value
      },
    })
    watch(isOpen, value => {
      if (isAccordion) isGroupExpanded.value = value
    })

    watch(menuIsVerticalNavMini, val => {
      if (val && !isMouseHovered) isGroupExpanded.value = false
      else {
        isGroupExpanded.value = isOpen.value
      }
    })

    watch(isMouseHovered, value => {
      if (menuIsVerticalNavMini.value) {
        if (value) isGroupExpanded.value = isOpen.value
        else isGroupExpanded.value = false
      }
    })

    // I18n
    const { t } = useUtils()

    return {
      isGroupExpanded,
      resolveNavItemComponent,
      isNavGroupActive,
      menuIsVerticalNavMini,
      refVListGroup,
      isMouseHovered,
      isOpen,
      isActive,
      updateGroupOpen,

      // Alternate Icon
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuGroup,
    }
  },
}
</script>

<style lang='scss'>
@import '~vuetify/src/styles/styles.sass';

.vertical-nav-menu-group {
  &.v-list-group--active > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: none;
  }

  & > .v-list-group__header > .v-list-group__header__append-icon .v-icon {
    transform: rotate(-90deg);
  }

  & & .v-list-group__items .v-list-item__icon {
    visibility: hidden;
  }
}

@include theme(vertical-nav-menu-group) using($material) {
  &.vertical-nav-menu-group-active > .v-list-group__header {
    background-color: rgba(map-deep-get($material, 'text', 'primary'), map-deep-get($material, 'states', 'selected'));
  }
}

.alternate-icon-small {
  font-size: 14px;
  height: 14px;
  width: 14px;
}
</style>
