import axios from '@/api/requests/axios'

export default {
  getApplicationTypes: () => axios.get(`/api/v1/dictionary/application-types`),
  getRoles: () => axios.get(`/api/v1/dictionary/admin/roles`),
  getContactTypes: () => axios.get(`/api/v1/dictionary/contact-types`),
  getContacts: () => axios.get('/api/v1/dictionary/contacts'),
  getApplicationDescriptionTypes: () => axios.get(`/api/v1/dictionary/application-description-types`),
  getVacancyTypes: () => axios.get(`/api/v1/dictionary/vacancy-types`),
  getSex: () => axios.get(`/api/v1/dictionary/sex`),
  getCounterPartyTypes: () => axios.get('/api/v1/dictionary/counter-party-types'),
  getOrderTypes: () => axios.get('/api/v1/dictionary/order-types'),
  getVacancyApplicationStatuses: () => axios.get('/api/v1/dictionary/vacancy-application-statuses'),
  getPaymentMethodTypes: () => axios.get('/api/v1/dictionary/payment-method-types'),
  getBranches: () => axios.get('/api/v1/dictionary/branches'),
  getOrderStates: () => axios.get('/api/v1/dictionary/order-states'),
  getApplianceTypes: () => axios.get('/api/v1/dictionary/appliance-types'),
  getCustomDishTypes: () => axios.get('/api/v1/dictionary/custom-dish-types'),
}
