import pushNotificationApi from '@/api/push-notification'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }, id) {
    state.loading = true;
    pushNotificationApi.histories(id)
      .then(( {data} ) => commit('SET_LIST', data))
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
}

const watchers = [
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
