import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/restaurants`, { params }),
  sync: () => axios.put(`/api/admin/v1/restaurants`),
  updateVisibility: (restaurant) => axios.put(`/api/admin/v1/restaurants/visibility`, restaurant),
  getConfigurationById: (id) => axios.get(`/api/admin/v1/restaurants/configuration/${id}`),
  updateConfiguration: (restaurantConfiguration) => axios.put(`/api/admin/v1/restaurants/configuration`, restaurantConfiguration),
  getForDropDown: (params) => axios.get('/api/admin/v1/restaurants/drop-down', { params }),
}
