import userApi from '@/api/user'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  userName: '',
  email: '',
  firstName: '',
  role: '',
  lastName: '',
  patronymic: '',
  password: '',
  confirmPassword: '',
  birthDate: '',
  phoneNumber: '',
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return userApi.create(state)
      .then(({ data }) => commit('USER_CREATED', data))
      .catch((error) => {
        commit('USER_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetch({ commit }){
    commit("FORM_RESET");
    userApi.get()
      .then(({ data }) => commit('ACCOUNT_FETCHED', data))
      .catch(err => commit('ACCOUNT_FETCH_FAILED', err))
  },
  update({ commit }) {
    return userApi.update(state)
      .then(({ data }) => commit('USER_UPDATED', data))
      .catch((error) => {
        commit('USER_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  updatePassword({commit}, data){
    return userApi.updatePassword(data)
      .then(({ data }) => commit('USER_PASSWORD_UPDATED'))
      .catch((error) => {
        commit('USER_PASSWORD_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  USER_CREATED() {
    store.dispatch('message/success', 'USER.created')
    store.dispatch('user/fetchList')
  },
  USER_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'USER.failedCreated')
  },
  USER_UPDATED() {
    store.dispatch('message/success', 'PROMOTION.updated')
    store.dispatch('promotion/fetchList')
  },
  USER_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'PROMOTION.failedUpdate')
  },
  USER_PASSWORD_UPDATED(){
    store.dispatch('message/success', 'PROMOTION.updated')
    store.dispatch('promotion/fetchList')
  },
  USER_PASSWORD_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'PROMOTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  ACCOUNT_FETCHED(state, data){
    Object.assign(state, data)
  },
  ACCOUNT_FETCHED_FAILED(){
    store.dispatch('message/error', 'PROMOTION.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
