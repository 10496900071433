<template>
  <v-snackbar
    v-model="display"
    :timeout="message.timeout"
    :color="message.status"
    top
    right
  >
    <v-btn dark text @click="display = false">Закрыть</v-btn>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Message',
  computed: {
    ...mapState({
      message: state => state.message,
    }),
    display: {
      get() { return this.$store.state.message.display },
      set() { this.$store.dispatch('message/clear')}
    }
  }
}
</script>
