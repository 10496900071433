import applicationDescriptionApi from '@/api/application-description'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  translations: [
    {
      language: 'ru',
      name: null,
      description: null,
    },
    {
      language: 'ky',
      name: null,
      description: null,
    },
    {
      language: 'en',
      name: null,
      description: null,
    },
  ],
  publicationDate: '',
  type: '',
  applicationTypes: [],
  isActive: false,
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return applicationDescriptionApi.create(state)
      .then(({ data }) => commit('APPLICATION_DESCRIPTION_CREATED', data))
      .catch((error) => {
        commit('APPLICATION_DESCRIPTION_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    applicationDescriptionApi.getById(id)
      .then(({ data }) => commit('APPLICATION_DESCRIPTION_FETCHED', data))
      .catch(err => commit('APPLICATION_DESCRIPTION_FETCH_FAILED', err))
  },
  update({ commit }) {
    return applicationDescriptionApi.update(state)
      .then(({ data }) => commit('APPLICATION_DESCRIPTION_UPDATED', data))
      .catch((error) => {
        commit('APPLICATION_DESCRIPTION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  APPLICATION_DESCRIPTION_CREATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.created')
    store.dispatch('applicationDescription/fetchList')
  },
  APPLICATION_DESCRIPTION_CREATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedDealer')
  },
  APPLICATION_DESCRIPTION_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('applicationDescription/fetchList')
  },
  APPLICATION_DESCRIPTION_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  APPLICATION_DESCRIPTION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
