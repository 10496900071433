import orderApi from '@/api/order'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  loading: false,
}

const getters = {
}

const actions = {
  fetchList({ commit }, id) {
    state.loading = true;
    orderApi.getWorkflowData(id)
      .then(( {data} ) => commit('SET_LIST', data))
  }
}

const mutations = {
  SET_LIST(state, items) {
    state.list = items;
    state.loading = false;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
}

const watchers = [
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
