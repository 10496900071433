import contactApi from '@/api/contact'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  name: '',
  link: '',
  phoneNumber: '',
  toShowOnMainPage: false,
  isHr: false,
  type: null,
  errors: {
    errors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return contactApi.create(state)
      .then(({ data }) => commit('CONTACT_CREATED', data))
      .catch((error) => {
        commit('CONTACT_CREATE_FAILED', error.response.data)
        throw error
      })
  },
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    contactApi.getById(id)
      .then(({ data }) => commit('CONTACT_FETCHED', data))
      .catch(err => commit('CONTACT_FETCH_FAILED', err))
  },
  update({ commit }) {
    return contactApi.update(state)
      .then(({ data }) => commit('CONTACT_UPDATED', data))
      .catch((error) => {
        commit('CONTACT_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  CONTACT_CREATED() {
    store.dispatch('message/success', 'CONTACT.created')
    store.dispatch('contact/fetchList')
  },
  CONTACT_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'CONTACT.failedCreate')
  },
  CONTACT_UPDATED() {
    store.dispatch('message/success', 'CONTACT.updated')
    store.dispatch('contact/fetchList')
  },
  CONTACT_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  CONTACT_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
