import systemSettingApi from '@/api/system-setting'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  groupId: 0,
  contactTypeId: 0,
  moneySpentAccountTypeId: 0,
  bonusAmountAccountTypeId: 0,
  discountDataAccountTypeId: 0,
  orderAmountAccountTypeId: 0,
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetch({ commit }) {
    commit('FORM_RESET')
    systemSettingApi.getCrmClientSetting()
      .then(({ data }) => commit('CRM_CLIENT_SETTING_FETCHED', data))
      .catch(err => commit('CRM_CLIENT_SETTING_FETCH_FAILED', err))
  },
  update({ commit }) {
    return systemSettingApi.updateCrmClientSetting(state)
      .then(({ data }) => commit('CRM_CLIENT_SETTING_UPDATED', data))
      .catch((error) => {
        commit('CRM_CLIENT_SETTING_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  CRM_CLIENT_SETTING_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
  },
  CRM_CLIENT_SETTING_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  CRM_CLIENT_SETTING_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
