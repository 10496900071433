import systemSettingApi from '@/api/system-setting'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  url: '',
  merchantId: 0,
  merchantSecret: '',
  secretPayout: '',
  addCardCallBackUrl: '',
  createOrderCallBackUrl: '',
  createMobileOrderSuccessUrl: '',
  createMobileOrderFailureUrl: '',
  createWebOrderSuccessUrl: '',
  createWebOrderFailureUrl: '',
  addCardSuccessUrl: '',
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetch({ commit }) {
    commit('FORM_RESET')
    systemSettingApi.getPayBoxSetting()
      .then(({ data }) => commit('PAY_BOX_SETTING_FETCHED', data))
      .catch(err => commit('PAY_BOX_SETTING_FETCH_FAILED', err))
  },
  update({ commit }) {
    return systemSettingApi.updatePayBoxSetting(state)
      .then(({ data }) => commit('PAY_BOX_SETTING_UPDATED', data))
      .catch((error) => {
        commit('PAY_BOX_SETTING_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  PAY_BOX_SETTING_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
  },
  PAY_BOX_SETTING_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  PAY_BOX_SETTING_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
