<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <span class="text-lg white--text">{{ user.avatar }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              src="@/assets/images/avatars/1.png"
            ></v-img>
<!--                        <v-icon-->
<!--                          v-else-->
<!--                          color="primary"-->
<!--                          size="28"-->
<!--                        >-->
<!--                          {{ icons.mdiAccountOutline }}-->
<!--                        </v-icon>-->
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ getUserName() }}
          </span>
          <small class="text--disabled text-capitalize">{{ user.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item :to="{ name: 'account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Профиль</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { mapState } from 'vuex'
import router from '@/router'
import {isEmptyOrSpaces} from '../../../../helpers/index'

export default {
  name: 'AppBarUserMenu',
  computed: {
    ...mapState({
      userData: state => state.auth.data,
    }),
  },
  data: () => ({
    user: {},
    icons: {
      mdiAccountOutline,
      mdiEmailOutline,
      mdiCheckboxMarkedOutline,
      mdiChatOutline,
      mdiCogOutline,
      mdiCurrencyUsd,
      mdiHelpCircleOutline,
      mdiLogoutVariant,
    },
  }),
  watch: {
    userData: {
       handler: function(){
         const userData = localStorage.getItem('userData')
         if (userData){
           this.user = JSON.parse(userData);
         }
       },
       deep: true
     }
  },
  methods: {
    async logoutUser() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      await router.push({ name: 'pages-login' })
    },
    getUserName(){
      return isEmptyOrSpaces(this.user.fullName) ?  this.user.userName : this.user.fullName;
    }
  },
  mounted() {
    const userData = localStorage.getItem('userData')
    if (userData){
      this.user = JSON.parse(userData);
    }
  }
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
