import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/application-infos`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/application-infos/${id}`),
  create: (applicationDescription) => axios.post(`/api/admin/v1/application-infos`, applicationDescription),
  update: (applicationDescription) => axios.put(`/api/admin/v1/application-infos`, applicationDescription),
  delete: (id) => axios.delete(`/api/admin/v1/application-infos/${id}`),
  activate: (id) => axios.post(`/api/admin/v1/application-infos/activate/${id}`),
  deactivate: (id) => axios.post(`/api/admin/v1/application-infos/deactivate/${id}`),
}
