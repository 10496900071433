import dishApi from '@/api/dish'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  translations: [
    {
      language: 'ru',
      name: '',
    },
    {
      language: 'ky',
      name: '',
    },
    {
      language: 'en',
      name: '',
    },
  ],
  menuCategoryId: 0,
  parts: [],
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return dishApi.createCustom(state)
      .then(({ data }) => commit('CUSTOM_DISH_CREATED', data))
      .catch((error) => {
        commit('CUSTOM_DISH_CREATE_FAILED', error.response.data)
        throw error
      })
  },
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    dishApi.getCustomDishById(id)
      .then(({ data }) => commit('CUSTOM_DISH_FETCHED', data))
      .catch(err => commit('CUSTOM_DISH_FETCH_FAILED', err))
  },
  update({ commit }) {
    return dishApi.updateCustom(state)
      .then(({ data }) => commit('CUSTOM_DISH_UPDATED', data))
      .catch((error) => {
        commit('CUSTOM_DISH_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  CUSTOM_DISH_CREATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.created')
    store.dispatch('dish/fetchList')
  },
  CUSTOM_DISH_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedDealer')
  },
  CUSTOM_DISH_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('dish/fetchList')
  },
  CUSTOM_DISH_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  CUSTOM_DISH_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
