import axios from 'axios'
import qs from 'qs'
import TokenStorage from '@/api/requests/token'
import config from '@/config'
import router from '@/router/index'
import store from '@/store'

let isRefreshing = false
let failedQueue = []

const processFailedQueue = (error = null) => {
  isRefreshing = false
  failedQueue.forEach(prom => (error ? prom.reject(error) : prom.resolve()))
  failedQueue = []
}

const instance = axios.create({
  baseURL: config.API_URL,
  timeout: config.API_REQUESTS_TIMEOUT,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

const injectAuthHeaders = config => {
  config.headers.Authorization = TokenStorage.getAuthenticationBearer()
  config.headers["Accept-Language"] = 'ru'
  return config
}

const handleAuthenticationError = async error => {
  if (error.config._retry) {
    return Promise.reject(error)
  }
  const originalRequest = error.config
  if (isRefreshing) {
    return new Promise(((resolve, reject) => {
      failedQueue.push({ resolve, reject })
    })).then(() => {
      originalRequest.headers.Authorization = TokenStorage.getAuthenticationBearer()
      return axios.request(originalRequest)
    }).catch(error => Promise.reject(error))
  }

  if (originalRequest.headers.Authorization !== TokenStorage.getAuthenticationBearer()) {
    originalRequest.headers.Authorization = TokenStorage.getAuthenticationBearer()

    return axios.request(originalRequest)
  }

  originalRequest._retry = true
  isRefreshing = true

  return TokenStorage.refreshToken()
    .then(() => {
      processFailedQueue()
      originalRequest.headers.Authorization = TokenStorage.getAuthenticationBearer()

      return axios.request(originalRequest)
    })
    .catch(error => {
      processFailedQueue(error)

      return Promise.reject(error)
    })
}

const handleRefreshTokenError =  async error => {
  TokenStorage.clear()
  if (router.currentRoute.path !== '/login') {
    await router.push({name: 'pages-login'})
  }

  return Promise.reject(error)
}

instance.interceptors.request.use(config => injectAuthHeaders(config))


instance.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      store.dispatch('message/error', '')
    } else {
      let path;
      switch (error.response.status) {
        case 401: {
          return handleAuthenticationError(error)
            .catch(error => handleRefreshTokenError(error))
        }
        case 403: path = '/403'; break
        case 404: path = '/404'; break
        case 500: {

        }
      }
      if (path) {
        router.push(path).catch(err => { console.log(err) })
      }
    }

    return Promise.reject(error)
  },
)

export default instance
