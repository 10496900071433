import vacancyApplicationApi from '@/api/vacancy-application'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  vacancy: '',
  name: '',
  phoneNumber: '',
  citizenship: '',
  email: '',
  dateOfBirth: '',
  education: '',
  hasMedicalPassport: false,
  seniority: '',
  additionalInformation: '',
  comment: '',
  reviewerComment: '',
  statusId: 0,
  creationTime: '',
  hasFile: false,
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    vacancyApplicationApi.getById(id)
      .then(({ data }) => commit('VACANCY_APPLICATION_FETCHED', data))
      .catch(err => commit('VACANCY_APPLICATION_FETCH_FAILED', err))
  },
  update({ commit }, data) {
    return vacancyApplicationApi.updateStatus(data)
      .then(({ data }) => commit('VACANCY_APPLICATION_UPDATED', data))
      .catch((error) => {
        commit('VACANCY_APPLICATION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  async getFile({commit}, id){
    await vacancyApplicationApi.getFile(id);
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  VACANCY_APPLICATION_UPDATED() {
    store.dispatch('message/success', 'VACANCY.updated')
    store.dispatch('vacancyApplication/fetchList')
  },
  VACANCY_APPLICATION_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'VACANCY.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  VACANCY_APPLICATION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  VACANCY_APPLICATION_FETCH_FAILED() {
    store.dispatch('message/error', 'VACANCY.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
