import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/push-notifications`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/push-notifications/${id}`),
  create: (pushNotification) => {
    let formData = new FormData()
    formData.append('file', pushNotification.file)
    formData.append('name', pushNotification.name)
    formData.append('description', pushNotification.description)
    formData.append('time', pushNotification.time)
    for (let i = 0; i < pushNotification.groupCriteriaIds.length; i++) {
      formData.append('groupCriteriaIds[' + i + ']', pushNotification.groupCriteriaIds[i])
    }
    for (let i = 0; i < pushNotification.customerIds.length; i++) {
      formData.append('customerIds[' + i + ']', pushNotification.customerIds[i])
    }
    return axios.post(`/api/admin/v1/push-notifications`, formData)
  },
  update: (pushNotification) => axios.put(`/api/admin/v1/push-notifications`, pushNotification),
  delete: (id) => axios.delete(`/api/admin/v1/push-notifications/${id}`),
  send: () => axios.post('/api/admin/v1/push-notifications/send'),
  previewPhones: (file) =>{
    let formData = new FormData()
    formData.append('file', file)
    return axios.post(`/api/admin/v1/push-notifications/preview-phones`, formData)
  },
  histories: (id) =>axios.get(`/api/admin/v1/push-notifications/histories/${id}`),
}
