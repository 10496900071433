import _ from 'lodash'
import restaurantApi from '@/api/restaurant'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'
import form from './form'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false,
  },
  dropdownList: []
}

const getters = {
  restaurantDropDown: state => state.dropdownList.map(x => ({ text: x.name, value: x.id })),
}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    restaurantApi.getAll({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
  sync({ commit }) {
    store.dispatch('message/load')
    return restaurantApi.sync()
      .then(() => commit('RESTAURANT_SYNCED'))
      .catch((error) => {
        commit('RESTAURANT_SYNC_FAILED', { errors: error.response.data.errors })
        throw error
      })
  },
  updateVisibility({commit}, data){
    return restaurantApi.updateVisibility(data)
      .then(() => commit('RESTAURANT_VISIBILITY_UPDATED', data))
      .catch((error) => {
        commit('RESTAURANT_VISIBILITY_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  fetchDropDown({ commit }) {
    return restaurantApi.getForDropDown()
      .then(({ data }) => commit('SET_RESTAURANT_TABS', data))
  }
}

const mutations = {
  SET_LIST(state, { items, metadata }) {
    state.list = items
    state.totalCount = metadata.totalItemCount
    state.loading = false
    state.filters.pageNumber = metadata.pageNumber
  },
  RESTAURANT_SYNCED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('restaurant/fetchList')
  },
  RESTAURANT_SYNC_FAILED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('message/error', '')
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  RESTAURANT_VISIBILITY_UPDATE_FAILED(state){
    store.dispatch('message/error', '')
  },
  RESTAURANT_VISIBILITY_UPDATED(){
    store.dispatch('restaurant/fetchList')
  },
  SET_RESTAURANT_TABS(state, items){
    state.dropdownList = items;
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
  form
}

const watchers = [
  [state => state.restaurant.filters, _.debounce(async () => await store.dispatch('restaurant/fetchList'), 250), {
    immediate: false,
    deep: true,
  }],
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
