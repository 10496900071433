import dishApi from '@/api/dish'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  name: '',
  price: 0,
  isCombo: false,
  isDelivery: false,
  recipe: '',
  weight: '',
  image: '',
  child: [],
  schemas: [],
  mayProvidePresent: false,
  applianceType: null,
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {
  child: state => state.child,
}

const actions = {
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    dishApi.getById(id)
      .then(({ data }) => commit('DISH_FETCHED', data))
      .catch(err => commit('DISH_FETCH_FAILED', err))
  },
  update({ commit }, customData) {
    dishApi.updateCustomData(customData)
      .then(({ data }) => commit('DISH_UPDATED', data))
      .catch(err => commit('DISH_UPDATE_FAILED', err))
  },
  uploadPhoto({ commit }, formData) {
    return dishApi.updateImage(formData)
      .then(({ data }) => commit('DISH_IMAGE_UPDATED', data))
      .catch((error) => {
        commit('DISH_IMAGE_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  removePhoto({ commit }, dishId) {
    return dishApi.removeDishImage(dishId)
      .then(({ data }) => commit('DISH_IMAGE_REMOVED', data))
      .catch((error) => {
        commit('DISH_IMAGE_REMOVE_FAILED', error.response.data)
        throw error
      })
  },
  getChild({ commit }, dishId) {
    return dishApi.getChild(dishId)
      .then(({ data }) => commit('DISH_CHILD_GET_SUCCESS', data))
      .catch((error) => {
        commit('DISH_CHILD_GET_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  DISH_IMAGE_REMOVED(state, data) {

  },
  DISH_CHILD_GET_FAILED() {
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  DISH_IMAGE_UPDATED() {
    store.dispatch('dish/fetchList')
  },
  DISH_UPDATED() {
  },
  DISH_UPDATE_FAILED(state, errors) {
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  DISH_IMAGE_UPDATE_FAILED(state, errors) {
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  DISH_FETCHED(state, data) {
    Object.assign(state, data)
  },
  DISH_CHILD_GET_SUCCESS(state, data) {
    state.child = data
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
