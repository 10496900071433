import { mdiBookOpenVariant } from '@mdi/js'

export default [
  {
    title: 'LookUps',
    icon: mdiBookOpenVariant,
    children: [
      {
        title: 'Contacts',
        to: 'contact-list',
        permission: 'Permissions.ContactRead',
      },
      {
        title: 'OrderTypes',
        to: 'order-type-list',
        permission: 'Permissions.OrderTypeRead',
      },
      {
        title: 'Promotions',
        to: 'promotion-list',
        permission: 'Permissions.PromotionRead',
      },
      {
        title: 'ApplicationDescription',
        to: 'application-description-list',
        permission: 'Permissions.ApplicationInfoRead',
      },
      {
        title: 'Vacancies',
        to: 'vacancy-list',
        permission: 'Permissions.VacancyRead',
      },
      {
        title: 'Cities',
        to: 'city-list',
        permission: 'Permissions.CityRead',
      },
      {
        title: 'GroupCriteria',
        to: 'group-criteria-list',
        permission: 'Permissions.GroupCriteriaRead',
      },
      {
        title: 'PushNotification',
        to: 'push-notification-list',
        permission: 'Permissions.PushNotificationRead',
      },
      {
        title: 'PaymentMethod',
        to: 'payment-method-list',
        permission: 'Permissions.PaymentMethodRead',
      },
      {
        title: 'Restaurant',
        to: 'restaurant-list',
        permission: 'Permissions.RestaurantRead',
      },
      {
        title: 'CounterParty',
        to: 'counter-party-list',
        permission: 'Permissions.CounterPartyRead',
      },
      {
        title: 'Menu',
        to: 'menu-version',
        permission: 'Permissions.MenuVersionRead',
      },
      {
        title: 'OrderState',
        to: 'order-state',
        permission: 'Permissions.OrderStateRead',
      },
      {
        title: 'PresentDish',
        to: 'present-dish-list',
        permission: 'Permissions.PresentDishRead',
      },
    ],
  },
]
