import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/custom-dishes`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/custom-dishes/${id}`),
  create: (customDish, image) => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('rkId', customDish.rkId)
    formData.append('type', customDish.type)
    if (customDish.applianceTypes !== null && customDish.applianceTypes.length >= 0){
      for (let i = 0; i < customDish.applianceTypes.length; i++) {
        formData.append(`applianceTypes[${i}].appliance`, customDish.applianceTypes[i].appliance)
        formData.append(`applianceTypes[${i}].amount`, customDish.applianceTypes[i].amount)
      }
    }
    for (let i = 0; i < customDish.translations.length; i++) {
      formData.append(`translations[${i}].language`, customDish.translations[i].language)
      formData.append(`translations[${i}].name`, customDish.translations[i].name)
    }
    return axios.post(`/api/admin/v1/custom-dishes`, formData)
  },
  update: (customDish, image) => {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('id', customDish.id)
    formData.append('rkId', customDish.rkId)
    formData.append('type', customDish.type)
    if (customDish.applianceTypes !== null && customDish.applianceTypes.length >= 0){
      for (let i = 0; i < customDish.applianceTypes.length; i++) {
        formData.append(`applianceTypes[${i}].appliance`, customDish.applianceTypes[i].appliance)
        formData.append(`applianceTypes[${i}].amount`, customDish.applianceTypes[i].amount)
      }
    }
    for (let i = 0; i < customDish.translations.length; i++) {
      formData.append(`translations[${i}].language`, customDish.translations[i].language)
      formData.append(`translations[${i}].name`, customDish.translations[i].name)
    }
    return axios.put(`/api/admin/v1/custom-dishes`, formData)
  },
  delete: (id) => axios.delete(`/api/admin/v1/custom-dishes/${id}`),
}
