import promotionApi from '@/api/promotiom'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  translations: [
    {
      language: 'ru',
      name: null,
      description: null,
      subtitle: null
    },
    {
      language: 'ky',
      name: null,
      description: null,
      subtitle: null
    },
    {
      language: 'en',
      name: null,
      description: null,
      subtitle: null
    }
  ],
  contacts: '',
  restaurants: '',
  image: '',
  dateRange: {
    start: null,
    end: null
  },
  sortIndex: 0,
  isBanner: false,
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }, image) {
    return promotionApi.create(state, image)
      .then(({ data }) => commit('PROMOTION_CREATED', data))
      .catch((error) => {
        commit('PROMOTION_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    promotionApi.getById(id)
      .then(({ data }) => commit('PROMOTION_FETCHED', data))
      .catch(err => commit('PROMOTION_FETCH_FAILED', err))
  },
  update({ commit }, image) {
    return promotionApi.update(state, image)
      .then(({ data }) => commit('PROMOTION_UPDATED', data))
      .catch((error) => {
        commit('PROMOTION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  PROMOTION_CREATED() {
    store.dispatch('message/success', 'PROMOTION.created')
    store.dispatch('promotion/fetchList')
  },
  PROMOTION_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'PROMOTION.failedDealer')
  },
  PROMOTION_UPDATED() {
    store.dispatch('message/success', 'PROMOTION.updated')
    store.dispatch('promotion/fetchList')
  },
  PROMOTION_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'PROMOTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  PROMOTION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  PROMOTION_FETCH_FAILED() {
    store.dispatch('message/error', 'PROMOTION.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
