<template>
  <v-dialog
    v-model="display"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Loader',
  computed: {
    ...mapState({
      message: state => state.message,
    }),
    display: {
      get() { return this.$store.state.message.displayLoader },
      set() { this.$store.dispatch('message/clear')}
    }
  }
}
</script>

<style scoped>

</style>
