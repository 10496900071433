import _ from 'lodash'
import systemSettingApi from '@/api/system-setting'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false,
  },
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    systemSettingApi.getAllCrm({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
}

const mutations = {
  SET_LIST(state, { items, metadata }) {
    state.list = items
    state.totalCount = metadata.totalItemCount
    state.loading = false
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
  form,
}

const watchers = [
  [state => state.crmSettings.filters, _.debounce(async () => await store.dispatch('crmSettings/fetchList'), 250), {
    immediate: false,
    deep: true,
  }],
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
