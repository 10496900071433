import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/order-types`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/order-types/${id}`),
  create: (orderType) => axios.post(`/api/admin/v1/order-types`, orderType),
  update: (orderType) => axios.put(`/api/admin/v1/order-types`, orderType),
  getTypes: () => axios.get('/api/admin/v1/order-types/rk7-types'),
  getCategories: () => axios.get('/api/admin/v1/order-types/categories'),
  getCurrencies: () => axios.get('/api/admin/v1/order-types/currencies'),
}
