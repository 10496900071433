import axios from '@/api/requests/axios'

export default {
  getStatistics: () => axios.get(`/api/admin/v1/reports/statistics`),
  getSalesByYear: (params) => axios.get(`/api/admin/v1/reports/sales-by-year`, { params }),
  getOrderYears: () => axios.get(`/api/admin/v1/reports/order-years`),
  getDigitalCurrencyOrders: (params) => axios.post('/api/admin/v1/reports/digital-currency', params),
  getDigitalCurrencyByBranchOrders: (params) => axios.post('/api/admin/v1/reports/digital-currency-by-branch', params),
  getDigitalCurrencyOrdersFile: (params) => axios.post('/api/admin/v1/reports/digital-currency/file', params, { responseType: 'blob' }),
  getDigitalCurrencyByBranchOrdersFile: (params) => axios.post('/api/admin/v1/reports/digital-currency-by-branch/file', params, { responseType: 'blob' }),
  syncOrders: (params) => axios.post('/api/admin/v1/reports/sync-by-period', params),
}
