import dictionaryApi from '@/api/dictionary'
import customerApi from '@/api/customer'
import cityApi from '@/api/city'
import dishApi from '@/api/dish'
import menuApi from '@/api/menu'
import orderTypeApi from '@/api/order-type'
import counterPartyApi from '@/api/counter-party'
import systemSettingsApi from '@/api/system-setting'
import paymentMethodApi from '@/api/payment-method'
import reportApi from '@/api/report'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  applicationTypesList: [],
  rolesList: [],
  contactTypesList: [],
  contactsList: [],
  vacancyTypesList: [],
  applicationDescriptionTypesList: [],
  sexList: [],
  customerList: [],
  cityList: [],
  classificatorGroupList: [],
  counterPartyTypeList: [],
  tableList: [],
  cashList: [],
  rk7OrderTypeList: [],
  orderCategoryList: [],
  currencies: [],
  orderTypesList: [],
  crmClientGroupsList: [],
  crmContactTypesList: [],
  rk7ReasonsList: [],
  vacancyApplicationStatusList: [],
  crmAccountTypesList: [],
  orderYearsList: [],
  paymentMethodTypesList: [],
  branchesList: [],
  orderStatesList: [],
  dishesList: [],
  applianceTypesList: [],
  customDishTypesList: [],
}

const getters = {
  customDishTypes: state => state.customDishTypesList,
  customDishTypesDropDown: state => state.customDishTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  applianceTypesDropDown: state => state.applianceTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  vacancyApplicationStatusTab: state => state.vacancyApplicationStatusList,
  orderYearsDropDown: state => state.orderYearsList,
  dishesDropDown: state => state.dishesList.map(x => ({ text: x.name, value: x.id })),
  branchesDropDown: state => state.branchesList.map(x => ({ text: x.name, value: x.id })),
  paymentMethodTypesDropDown: state => state.paymentMethodTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  crmAccountTypesDropDown: state => state.crmAccountTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  rk7ReasonsDropDown: state => state.rk7ReasonsList.map(x => ({ text: x.name, value: x.id })),
  crmClientGroupsDropDown: state => state.crmClientGroupsList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  crmContactTypesDropDown: state => state.crmContactTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  orderTypesDropDown: state => state.orderTypesList.map(x => ({ text: x.name, value: x.id })),
  rk7OrderTypeDropDown: state => state.rk7OrderTypeList.map(x => ({ text: x.name, value: x.code })),
  orderCategoryDropDown: state => state.orderCategoryList.map(x => ({
    text: x.name,
    value: x.code,
  })),
  currencyDropDown: state => state.currencies.map(x => ({ text: x.name, value: x.code })),
  currencyDropDownId: state => state.currencies.map(x => ({ text: x.name, value: x.id })),
  tableDropDown: state => state.tableList.map(x => ({ text: x.name, value: x.code })),
  cashDropDown: state => state.cashList.map(x => ({ text: x.name, value: x.code })),
  counterPartyTypeDropDown: state => state.counterPartyTypeList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  classificatorGroupDropDown: state => state.classificatorGroupList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  customerDropdown: state => state.customerList.map(x => ({ text: x.name, value: x.id })),
  cityDropDown: state => state.cityList.map(x => ({ text: x.name, value: x.id })),
  applicationTypesDropdown: state => state.applicationTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  applicationDescriptionTypesDropdown: state => state.applicationDescriptionTypesList.map(x => ({
    text: x.name,
    value: x.id,
  })),
  rolesDropdown: state => state.rolesList.map(x => ({ text: x.name, value: x.id })),
  contactTypesDropdown: state => state.contactTypesList.map(x => ({ text: x.name, value: x.id })),
  contactsDropdown: state => state.contactsList.map(x => ({ text: x.name, value: x.id })),
  vacancyTypesDropdown: state => state.vacancyTypesList.map(x => ({ text: x.name, value: x.id })),
  sexDropdown: state => state.sexList.map(x => ({ text: x.name, value: x.id })),
  orderStatesDropDown: state => state.orderStatesList.map(x => ({ text: x.name, value: x.id })),
}

const actions = {
  fetchApplicationTypes({ commit }) {
    dictionaryApi.getApplicationTypes()
      .then(({ data }) => commit('SET_APPLICATION_TYPES_LIST', data),
      )
  },
  fetchDishesForMenuVersion({ commit }, id) {
    dishApi.getForDropDown(id).then(({ data }) => commit('SET_DISHES_LIST', data))
  },
  fetchOrderTypes({ commit }) {
    dictionaryApi.getOrderTypes().then(({ data }) => commit('SET_ORDER_TYPES_LIST', data))
  },
  fetchRoles({ commit }) {
    dictionaryApi.getRoles()
      .then(({ data }) => commit('SET_ROLES_LIST', data),
      )
  },
  fetchContactTypes({ commit }) {
    dictionaryApi.getContactTypes()
      .then(({ data }) => commit('SET_CONTACT_TYPES_LIST', data))
  },
  fetchContacts({ commit }) {
    dictionaryApi.getContacts()
      .then(({ data }) => commit('SET_CONTACTS_LIST', data))
  },
  fetchApplicationDescriptionTypes({ commit }) {
    dictionaryApi.getApplicationDescriptionTypes()
      .then(({ data }) => commit('SET_APPLICATION_DESCRIPTION_TYPES_LIST', data))
  },
  fetchVacancyTypes({ commit }) {
    dictionaryApi.getVacancyTypes()
      .then(({ data }) => commit('SET_VACANCY_TYPES_LIST', data))
  },
  fetchSex({ commit }) {
    dictionaryApi.getSex()
      .then(({ data }) => commit('SET_SEX_LIST', data))
  },
  fetchCustomersDropdown({ commit }, payload) {
    return customerApi.getCustomersDropdown(payload)
      .then(({ data }) => commit('SET_CUSTOMER_DROPDOWN', data))
  },
  fetchCities({ commit }) {
    return cityApi.getDropDown()
      .then(({ data }) => commit('SET_CITY_DROPDOWN', data))
  },
  fetchClassificatorGroups({ commit }) {
    return menuApi.getClassificatorGroups()
      .then(({ data }) => commit('SET_CLASSIFICATOR_GROUP_DROPDOWN', data))
  },
  fetchCounterPartyTypes({ commit }) {
    dictionaryApi.getCounterPartyTypes()
      .then(({ data }) => commit('SET_COUNTER_PARTY_LIST', data))
  },
  fetchPaymentMethodTypes({ commit }) {
    dictionaryApi.getPaymentMethodTypes()
      .then(({ data }) => commit('SET_PAYMENT_METHOD_TYPES_LIST', data))
  },
  fetchCashes({ commit }) {
    counterPartyApi.getCashes().then(({ data }) => commit('SET_CASH_LIST', data))
  },
  fetchTables({ commit }) {
    counterPartyApi.getTables().then(({ data }) => commit('SET_TABLE_LIST', data))
  },
  fetchRk7OrderTypes({ commit }) {
    orderTypeApi.getTypes().then(({ data }) => commit('SET_RK7_ORDER_TYPE_LIST', data))
  },
  fetchOrderCategories({ commit }) {
    orderTypeApi.getCategories().then(({ data }) => commit('SET_ORDER_CATEGORY_LIST', data))
  },
  fetchCurrencies({ commit }) {
    orderTypeApi.getCurrencies().then(({ data }) => commit('SET_CURRENCY_LIST', data))
  },
  fetchCrmClientGroups({ commit }) {
    systemSettingsApi.getCrmClientGroups().then(({ data }) => commit('SET_CRM_CLIENT_GROUPS', data))
  },
  fetchCrmContactTypes({ commit }) {
    systemSettingsApi.getCrmContactTypes().then(({ data }) => commit('SET_CRM_CONTACT_TYPES', data))
  },
  fetchRk7Reasons({ commit }) {
    paymentMethodApi.getReasons().then(({ data }) => commit('SET_RK7_REASONS_LIST', data))
  },
  fetchVacancyApplicationStatuses({ commit }) {
    dictionaryApi.getVacancyApplicationStatuses().then(({ data }) => commit('SET_VACANCY_APPLICATION_STATUS_LIST', data))
  },
  fetchCrmAccountTypes({ commit }) {
    systemSettingsApi.getCrmAccountTypes().then(({ data }) => commit('SET_CRM_ACCOUNT_TYPES_LIST', data))
  },
  fetchOrdersYears({ commit }) {
    reportApi.getOrderYears().then(({ data }) => commit('SET_ORDERS_YEARS_LIST', data))
  },
  fetchBranches({ commit }) {
    dictionaryApi.getBranches().then(({ data }) => commit('SET_BRANCHES_LIST', data))
  },
  fetchOrderStates({ commit }) {
    dictionaryApi.getOrderStates().then(({ data }) => commit('SET_ORDER_STATES_LIST', data))
  },
  fetchApplianceTypes({ commit }) {
    dictionaryApi.getApplianceTypes().then(({ data }) => commit('SET_APPLIANCE_TYPES_LIST', data))
  },
  fetchCustomDishTypes({ commit }) {
    dictionaryApi.getCustomDishTypes().then(({ data }) => commit('SET_CUSTOM_DISH_TYPES_LIST', data))
  },
}

const mutations = {
  SET_APPLICATION_TYPES_LIST(state, data) {
    state.applicationTypesList = data
  },
  SET_ROLES_LIST(state, data) {
    state.rolesList = data
  },
  SET_CONTACT_TYPES_LIST(state, data) {
    state.contactTypesList = data
  },
  SET_CONTACTS_LIST(state, data) {
    state.contactsList = data
  },
  SET_APPLICATION_DESCRIPTION_TYPES_LIST(state, data) {
    state.applicationDescriptionTypesList = data
  },
  SET_VACANCY_TYPES_LIST(state, data) {
    state.vacancyTypesList = data
  },
  SET_SEX_LIST(state, data) {
    state.sexList = data
  },
  SET_CUSTOMER_DROPDOWN(state, items) {
    state.customerList = items
  },
  SET_CITY_DROPDOWN(state, items) {
    state.cityList = items
  },
  SET_CLASSIFICATOR_GROUP_DROPDOWN(state, items) {
    state.classificatorGroupList = items
  },
  SET_PAYMENT_METHOD_TYPES_LIST(state, items) {
    state.paymentMethodTypesList = items
  },
  SET_COUNTER_PARTY_LIST(state, items) {
    state.counterPartyTypeList = items
  },
  SET_CASH_LIST(state, items) {
    state.cashList = items
  },
  SET_TABLE_LIST(state, items) {
    state.tableList = items
  },
  SET_RK7_ORDER_TYPE_LIST(state, items) {
    state.rk7OrderTypeList = items
  },
  SET_ORDER_CATEGORY_LIST(state, items) {
    state.orderCategoryList = items
  },
  SET_CURRENCY_LIST(state, items) {
    state.currencies = items
  },
  SET_ORDER_TYPES_LIST(state, items) {
    state.orderTypesList = items
  },
  SET_CRM_CLIENT_GROUPS(state, items) {
    state.crmClientGroupsList = items
  },
  SET_CRM_CONTACT_TYPES(state, items) {
    state.crmContactTypesList = items
  },
  SET_RK7_REASONS_LIST(state, items) {
    state.rk7ReasonsList = items
  },
  SET_VACANCY_APPLICATION_STATUS_LIST(state, items) {
    state.vacancyApplicationStatusList = items
  },
  SET_CRM_ACCOUNT_TYPES_LIST(state, items) {
    state.crmAccountTypesList = items
  },
  SET_ORDERS_YEARS_LIST(state, items) {
    state.orderYearsList = items
  },
  SET_BRANCHES_LIST(state, items) {
    state.branchesList = items
  },
  SET_ORDER_STATES_LIST(state, items) {
    state.orderStatesList = items
  },
  SET_DISHES_LIST(state, items) {
    state.dishesList = items
  },
  SET_APPLIANCE_TYPES_LIST(state, items) {
    state.applianceTypesList = items
  },
  SET_CUSTOM_DISH_TYPES_LIST(state, items) {
    state.customDishTypesList = items
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
