import axios from '@/api/requests/axios'

export default {
  getAllRk7: (params) => axios.get(`/api/admin/v1/system-settings/rk7`, { params }),
  getRk7ById: (id) => axios.get(`/api/admin/v1/system-settings/rk7/${id}`),
  createRk7: (rk7Setting) => axios.post(`/api/admin/v1/system-settings/rk7`, rk7Setting),
  updateRk7: (rk7Setting) => axios.put(`/api/admin/v1/system-settings/rk7`, rk7Setting),
  getAllCrm: (params) => axios.get(`/api/admin/v1/system-settings/crm`, { params }),
  getCrmById: (id) => axios.get(`/api/admin/v1/system-settings/crm/${id}`),
  createCrm: (crmSetting) => axios.post(`/api/admin/v1/system-settings/crm`, crmSetting),
  updateCrm: (crmSetting) => axios.put(`/api/admin/v1/system-settings/crm`, crmSetting),
  getSmsSetting: () => axios.get('/api/admin/v1/system-settings/sms'),
  updateSmsSetting: (smsSetting) => axios.put(`/api/admin/v1/system-settings/sms`, smsSetting),
  getCrmClientSetting: () => axios.get('/api/admin/v1/system-settings/crm-client'),
  updateCrmClientSetting: (crmClientSetting) => axios.put('/api/admin/v1/system-settings/crm-client', crmClientSetting),
  getCrmClientGroups: () => axios.get('/api/admin/v1/system-settings/crm-client-groups'),
  getCrmContactTypes: () => axios.get('/api/admin/v1/system-settings/crm-contact-types'),
  getCrmAccountTypes: () => axios.get('/api/admin/v1/system-settings/crm-account-types'),
  getQrCodeSetting: () => axios.get('/api/admin/v1/system-settings/qr-code'),
  updateQrCodeSetting: (qrCodeSetting) => axios.put('/api/admin/v1/system-settings/qr-code', qrCodeSetting),
  getReferralCodeSetting: () => axios.get('/api/admin/v1/system-settings/referral-code'),
  updateReferralCodeSetting: (referralCodeSetting) => axios.put('/api/admin/v1/system-settings/referral-code', referralCodeSetting),
  getPayBoxSetting: () => axios.get('/api/admin/v1/system-settings/pay-box'),
  updatePayBoxSetting: (payBoxSetting) => axios.put('/api/admin/v1/system-settings/pay-box', payBoxSetting),
  getOrderCancellationSettings: () => axios.get('/api/admin/v1/system-settings/order-cancellation-settings'),
  updateOrderCancellationSettings: (orderCancellationSetting) => axios.put('/api/admin/v1/system-settings/order-cancellation-settings', orderCancellationSetting),
}
