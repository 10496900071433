import counterPartyApi from '@/api/counter-party'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  type: '',
  rk7TableCode: '',
  rk7StationCode: '',
  externalSystemCode: '',
  comment: '',
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return counterPartyApi.create(state)
      .then(({ data }) => commit('APPLICATION_DESCRIPTION_CREATED', data))
      .catch((error) => {
        commit('APPLICATION_DESCRIPTION_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    counterPartyApi.getById(id)
      .then(({ data }) => commit('COUNTER_PARTY_FETCHED', data))
      .catch(err => commit('APPLICATION_DESCRIPTION_FETCH_FAILED', err))
  },
  update({ commit }) {
    return counterPartyApi.update(state)
      .then(({ data }) => commit('APPLICATION_DESCRIPTION_UPDATED', data))
      .catch((error) => {
        commit('APPLICATION_DESCRIPTION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  APPLICATION_DESCRIPTION_CREATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.created')
    store.dispatch('counterParty/fetchList')
  },
  APPLICATION_DESCRIPTION_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedDealer')
  },
  APPLICATION_DESCRIPTION_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('counterParty/fetchList')
  },
  APPLICATION_DESCRIPTION_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  COUNTER_PARTY_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
