<template>
  <v-fade-transition mode="out-in">
    <v-btn
      icon
      @click="clearCache"
    >
      <v-icon size="18">
        {{ icons.mdiNuke }}
      </v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>
import cacheApi from '@/api/cache'
import { mdiNuke } from '@mdi/js'
import store from '@/store'

export default {
  name: 'AppBarClearCache',
  data: () => ({
    updateDialog: false,
    icons: {
      mdiNuke,
    },
  }),
  methods: {
    clearCache() {
      cacheApi.clear()
        .then(() => {
          store.dispatch('message/success')
        })
        .catch(() => {
          store.dispatch('message/error')
        })
    },
  },
}
</script>
