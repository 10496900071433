import Vue from 'vue'
import Vuex from 'vuex'
import appConfigStoreModule from '@/core/app-config/appConfigStoreModule'
import auth from './auth'
import app from './app'
import promotion from './modules/promotion/index'
import message from './message'
import applicationDescription from './modules/application-description/index'
import dictionary from './modules/dictionary/index'
import user from './modules/user/index'
import contact from './modules/contact/index'
import vacancy from './modules/vacancy/index'
import feedback from './modules/feedback/index'
import city from './modules/city/index'
import groupCriteria from './modules/group-criteria/index'
import pushNotification from './modules/push-notification/index'
import street from './modules/street/index'
import paymentMethod from './modules/payment-method/index'
import deliveryArea from './modules/delivery-area/index'
import rk7Settings from './modules/rk7-settings/index'
import crmSettings from './modules/crm-settings/index'
import smsSettings from './modules/sms-settings/index'
import orderType from './modules/order-type/index'
import role from './modules/role/index'
import customer from './modules/customer/index'
import restaurant from './modules/restaurant/index'
import branch from './modules/branch/index'
import order from './modules/order/index'
import menuVersion from './modules/menu-version/index'
import menuCategory from './modules/menu-category/index'
import dish from './modules/dish/index'
import counterParty from './modules/counter-party/index'
import crmClientSettings from './modules/crm-client-settings/index'
import qrCodeSettings from './modules/qr-code-settings/index'
import vacancyApplication from './modules/vacancy-application/index'
import reportStatistics from './modules/report/statistics/index'
import salesByYear from './modules/report/sales-by-year/index'
import referralCodeSettings from './modules/referral-code-settings/index'
import payBoxSettings from './modules/pay-box-settings/index'
import orderLogs from './modules/order-logs/index'
import digitalCurrency from './modules/report/digiral-currency/index'
import digitalCurrencyByBranch from './modules/report/digital-currency-by-branch/index'
import orderState from './modules/order-state/index'
import orderWorkflowItems from '@/store/modules/order-workflow-items/index'
import pushNotificationHistory from '@/store/modules/push-notification-history/index'
import dishTranslations from '@/store/modules/dish-translation/index'
import modifierGroupTranslations from '@/store/modules/modifier-group-translation/index'
import modifierGroupDetailsTranslations from '@/store/modules/modifier-group-detail-translation/index'
import modifierSchemeTranslations from '@/store/modules/modifier-scheme-translation/index'
import orderCancellationSettings from '@/store/modules/order-cancellation-settings/index'
import customDishes from '@/store/modules/custom-dish/index'

Vue.use(Vuex)

const storeModules = {
  auth,
  vacancy,
  menuCategory,
  pushNotification,
  orderCancellationSettings,
  message,
  pushNotificationHistory,
  promotion,
  orderLogs,
  applicationDescription,
  dictionary,
  customDishes,
  menuVersion,
  modifierGroupDetailsTranslations,
  modifierGroupTranslations,
  salesByYear,
  modifierSchemeTranslations,
  orderWorkflowItems,
  reportStatistics,
  orderState,
  user,
  payBoxSettings,
  digitalCurrencyByBranch,
  restaurant,
  qrCodeSettings,
  paymentMethod,
  referralCodeSettings,
  order,
  street,
  crmClientSettings,
  dish,
  app,
  digitalCurrency,
  customer,
  dishTranslations,
  smsSettings,
  vacancyApplication,
  deliveryArea,
  city,
  branch,
  groupCriteria,
  feedback,
  counterParty,
  contact,
  orderType,
  rk7Settings,
  role,
  crmSettings,
  appConfig: appConfigStoreModule
}

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: storeModules,
})


const recursivelyProcessWatchers = (module) => {
  if (module.watchers) {
    module.watchers.forEach((params) => {
      store.watch(...params)
    })
  }
  if (!module.modules) {
    return
  }
  for (const subModule of Object.keys(module.modules)) {
    recursivelyProcessWatchers(module.modules[subModule])
  }
}

Object.keys(storeModules).forEach(moduleName => {
  recursivelyProcessWatchers(storeModules[moduleName])
})

export default store
