import _ from 'lodash'
import groupCriteriaApi from '@/api/group-criteria'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  dropdownItems: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false,
  }
}

const getters = {
  dropdown: state => state.dropdownItems.map(x => ({ text: x.name, value: x.id })),
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    groupCriteriaApi.getAll({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
  delete({ commit }, id) {
    return groupCriteriaApi.delete(id)
      .then(() => commit('GROUP_CRITERIA_REMOVED', id))
      .catch((error) => {
        commit('GROUP_CRITERIA_REMOVE_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });
  },
  fetchDropdown({ commit }, payload) {
    return groupCriteriaApi.getDropDown(payload)
      .then(({ data }) => commit('SET_DROPDOWN', data))
  },
}

const mutations = {
  SET_DROPDOWN(state, items) {
    state.dropdownItems = items;
  },
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  GROUP_CRITERIA_REMOVED(state, id) {
    store.dispatch('groupCriteria/fetchList');
    store.dispatch('message/success', 'GROUP_CRITERIA.success')
  },
  GROUP_CRITERIA_REMOVE_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'GROUP_CRITERIA.removeFailure')
    state.errors = errors;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.groupCriteria.filters, _.debounce(async () => await store.dispatch('groupCriteria/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
