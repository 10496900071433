import axios from '@/api/requests/axios'
import config from '@/config'
import router from '@/router'

class TokenStorage {
  TOKEN_ACCESS = 'access_token';
  TOKEN_REFRESH = 'refresh_token';

  isAuthenticated() {
    return this.getAccessToken() !== null
  }

  async refreshToken() {
    let body = {
      refreshToken: this.getRefreshToken()
    }
    const { data } = await axios({
      method: 'post',
      url: config.API_URL + 'api/admin/v1/authorization/refresh-token',
      data: body,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    return this.storeTokens(data)
  }

  getAuthenticationBearer() {
    return `Bearer ${this.getAccessToken()}`
  }

  storeTokens({ accessToken, refreshToken }) {
    this.storeAccessToken(accessToken)
    this.storeRefreshToken(refreshToken)

    return { accessToken, refreshToken }
  }

  getPermissions(){
    return localStorage.getItem('userData');
  }

  getAccessToken() {
    return sessionStorage.getItem(this.TOKEN_ACCESS) || localStorage.getItem(this.TOKEN_ACCESS)
  }

  getRefreshToken() {
    return localStorage.getItem(this.TOKEN_REFRESH)
  }

  storeAccessToken(access_token) {
    localStorage.setItem(this.TOKEN_ACCESS, access_token)
  }

  storeRefreshToken(refreshToken) {
    localStorage.setItem(this.TOKEN_REFRESH, refreshToken)
  }

  clear() {
    localStorage.removeItem(this.TOKEN_ACCESS)
    localStorage.removeItem(this.TOKEN_REFRESH)
  }
}

const tokenStorage = new TokenStorage()

export default tokenStorage
