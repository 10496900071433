import axios from '@/api/requests/axios'

export default {
  getAll: (id) => axios.get(`/api/admin/v1/menu-categories?menuVersionId=${id}`),
  getById: (id) => axios.get(`/api/admin/v1/menu-categories/${id}`),
  create: (menuCategory) => axios.post('/api/admin/v1/menu-categories', menuCategory),
  update: (menuCategory) => axios.put('/api/admin/v1/menu-categories', menuCategory),
  hide: (id) => axios.patch(`/api/admin/v1/menu-categories/hide/${id}`),
  uhhide: (id) => axios.patch(`/api/admin/v1/menu-categories/unhide/${id}`),
}
