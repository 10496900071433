import reportsApi from '@/api/report'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  customerCount: 0,
  totalSales: 0,
  dishCount: 0,
  averageChequeSum: 0,
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetch({ commit }) {
    commit('FORM_RESET')
    reportsApi.getStatistics()
      .then(({ data }) => commit('STATISTICS_FETCHED', data))
      .catch(err => commit('STATISTICS_FETCH_FAILED', err))
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  STATISTICS_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
