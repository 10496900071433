import menuCategoryApi from '@/api/menu-category'
import { generateFormHandlerMutations } from '@/helpers/forms'
import form from './form'
import store from '@/store'

const state = {
  list: [],
  params: null,
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    menuCategoryApi.getAll(state.params)
      .then(({ data }) => commit('SET_LIST', data))
  },
  hide({ commit }, id) {
    menuCategoryApi.hide(id).then(() => commit('HIDE_MENU_CATEGORY', id))
  },
  unHide({ commit }, id) {
    menuCategoryApi.uhhide(id).then(() => commit('UNHIDE_MENU_CATEGORY', id))
  },
}

const modules = {
  form,
}

const mutations = {
  SET_LIST(state, items) {
    state.list = items
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  HIDE_MENU_CATEGORY(state, id) {
    store.dispatch('menuCategory/fetchList')
  },
  UNHIDE_MENU_CATEGORY(state, id) {
    store.dispatch('menuCategory/fetchList')
  },
  ...generateFormHandlerMutations(state),
}

const watchers = []

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
