import branchApi from '@/api/branch'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  branchId: null,
  cityId: null,
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    branchApi.getById(id)
      .then(({ data }) => commit('BRANCH_FETCHED', data))
      .catch(err => commit('CONTACT_FETCH_FAILED', err))
  },
  update({ commit }) {
    return branchApi.updateCity(state)
      .then(() => commit('BRANCH_UPDATED'))
      .catch((error) => {
        commit('BRANCH_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  BRANCH_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  BRANCH_UPDATED(){
    store.dispatch('branch/fetchList')
  },
  BRANCH_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
