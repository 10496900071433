import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/group-criteria`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/group-criteria/${id}`),
  create: (groupCriteria) => axios.post(`/api/admin/v1/group-criteria`, groupCriteria),
  update: (groupCriteria) => axios.put(`/api/admin/v1/group-criteria`, groupCriteria),
  delete: (id) => axios.delete(`/api/admin/v1/group-criteria/${id}`),
  getDropDown: (params) => axios.get(`/api/admin/v1/group-criteria/drop-down`, { params }),
}
