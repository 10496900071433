import _ from 'lodash'
import paymentMethodApi from '@/api/payment-method'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    paymentMethodApi.getAll({...state.filters})
      .then(( {data} ) => commit('SET_LIST', data))
  },
  delete({ commit }, id) {
    return paymentMethodApi.delete(id)
      .then(() => commit('PAYMENT_METHOD_REMOVED', id))
      .catch((error) => {
        commit('PAYMENT_METHOD_REMOVE_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });
  }
}

const mutations = {
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  PAYMENT_METHOD_REMOVED(state, id) {
    store.dispatch('paymentMethod/fetchList');
    store.dispatch('message/success', 'CONTACT.success')
  },
  PAYMENT_METHOD_REMOVE_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'CONTACT.removeFailure')
    state.errors = errors;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.paymentMethod.filters, _.debounce(async () => await store.dispatch('paymentMethod/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
