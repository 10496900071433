import restaurantApi from '@/api/restaurant'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  rk7ClassificatorGroupId: 0,
  logo: '',
  urlName: '',
  isDefault: false,
  maxDishAmountInOrder: 0,
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchConfigurationById({ commit }, id) {
    commit("FORM_RESET");
    restaurantApi.getConfigurationById(id)
      .then(({ data }) => commit('RESTAURANT_CONFIGURATION_FETCHED', data))
      .catch(err => commit('RESTAURANT_CONFIGURATION_FETCH_FAILED', err))
  },
  updateConfiguration({ commit }) {
    return restaurantApi.updateConfiguration(state)
      .catch((error) => {
        commit('RESTAURANT_CONFIGURATION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  RESTAURANT_CONFIGURATION_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'PUSH_NOTIFICATION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  RESTAURANT_CONFIGURATION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  RESTAURANT_CONFIGURATION_FETCH_FAILED() {
    store.dispatch('message/error', 'PUSH_NOTIFICATION.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
