import _ from 'lodash'
import customDishApi from '@/api/custom-dish'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    type: '',
    sortBy: '',
    sortDesc: false,
  },
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    customDishApi.getAll({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
  delete({ commit }, id) {
    return customDishApi.delete(id)
      .then(() => commit('PRESENT_DISH_REMOVED', id))
      .catch((error) => {
        commit('PRESENT_DISH_REMOVE_FAILED', { id, errors: error.response.data.errors })
        throw error
      })

  },
}

const mutations = {
  SET_LIST(state, { items, metadata }) {
    state.list = items
    state.totalCount = metadata.totalItemCount
    state.loading = false
  },
  PRESENT_DISH_REMOVED(state, id) {
    store.dispatch('customDishes/fetchList')
    store.dispatch('message/success', 'PRESENT_DISH.success')
  },
  PRESENT_DISH_REMOVE_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'PRESENT_DISH.removeFailure')
    state.errors = errors
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
  form,
}

const watchers = [
  [state => state.customDishes.filters, _.debounce(async () => await store.dispatch('customDishes/fetchList'), 250), {
    immediate: false,
    deep: true,
  }],
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
