import { mdiCogOutline } from '@mdi/js'

export default [
  {
    title: 'Administration',
    icon: mdiCogOutline,
    children: [
      {
        title: 'Orders',
        to: 'order-list',
        permission: 'Permissions.OrderRead'
      },
      {
        title: 'Users',
        to: 'user-list',
        permission: 'Permissions.UserRead'
      },
      {
        title: 'Roles',
        to: 'role-list',
        permission: 'Permissions.RoleRead'
      },
      {
        title: 'SystemSettings',
        to: 'system-settings',
        permission: 'Permissions.SettingsRead'
      },
      {
        title: 'Feedback',
        to: 'feedback-list',
        permission: 'Permissions.FeedBackRead'
      },
      {
        title: 'Customer',
        to: 'customer-list',
        permission: 'Permissions.CustomerRead'
      },
      {
        title: 'VacancyApplication',
        to: 'vacancy-application-list',
        permission: 'Permissions.VacancyApplicationRead'
      },
    ]
  },
]
