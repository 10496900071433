import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/dishes`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/dishes/${id}`),
  updateImage: (formData) => axios.put(`/api/admin/v1/dishes`, formData),
  getChild: (id) => axios.get(`/api/admin/v1/dishes/child/${id}`),
  removeDishImage: (id) => axios.delete(`/api/admin/v1/dishes/image/${id}`),
  createCustom: (customDish) => axios.post('/api/admin/v1/dishes/custom-dish', customDish),
  updateCustom: (customDish) => axios.put('/api/admin/v1/dishes/custom-dish', customDish),
  getCustomDishById: (id) => axios.get(`/api/admin/v1/dishes/custom-dish/${id}`),
  getForDropDown: (menuCategoryId) => axios.get(`/api/admin/v1/dishes/drop-down?menuVersion=${menuCategoryId}`),
  getTranslations: (params) => axios.get(`/api/admin/v1/dishes/translations`, { params }),
  getTranslationById: (id) => axios.get(`/api/admin/v1/dishes/translations/${id}`),
  updateDishTranslation: (translation) => axios.put(`/api/admin/v1/dishes/translations`, translation),
  getModifierGroupTranslations: (params) => axios.get(`/api/admin/v1/dishes/translations/modifier-groups`, { params }),
  getModifierGroupTranslationById: (id) => axios.get(`/api/admin/v1/dishes/translations/modifier-groups/${id}`),
  updateModifierGroupTranslation: (translation) => axios.put(`/api/admin/v1/dishes/translations/modifier-groups`, translation),
  getModifierGroupDetailsTranslations: (params) => axios.get(`/api/admin/v1/dishes/translations/modifier-group-details`, { params }),
  getModifierGroupDetailTranslationById: (id) => axios.get(`/api/admin/v1/dishes/translations/modifier-group-details/${id}`),
  updateModifierGroupDetailTranslation: (translation) => axios.put(`/api/admin/v1/dishes/translations/modifier-group-details`, translation),
  getModifierSchemeTranslations: (params) => axios.get(`/api/admin/v1/dishes/translations/modifier-schemes`, { params }),
  getModifierSchemeTranslationById: (id) => axios.get(`/api/admin/v1/dishes/translations/modifier-schemes/${id}`),
  updateModifierSchemeTranslation: (translation) => axios.put(`/api/admin/v1/dishes/translations/modifier-schemes`, translation),
  updateCustomData: (data) => axios.put('/api/admin/v1/dishes/custom-data', data),
}
