import { mdiFileDocument } from '@mdi/js'

export default [
  {
    title: 'Reports',
    icon: mdiFileDocument,
    children: [
      {
        title: 'DigitalCurrency',
        to: 'digital-currency',
        permission: 'Permissions.ContactRead',
      },
    ],
  },
]
