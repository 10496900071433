import _ from 'lodash'
import dishApi from '@/api/dish'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    sortBy: '',
    sortDesc: false,
    menuVersion: 0
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    dishApi.getModifierGroupDetailsTranslations({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
}

const mutations = {
  SET_DROPDOWN(state, items) {
    state.dropdownItems = items;
  },
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.modifierGroupDetailsTranslations.filters, _.debounce(async () => await store.dispatch('modifierGroupDetailsTranslations/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
