export const mapFormHandlers = (module, fields) =>
  fields.reduce(
    (obj, key) =>
      key.includes('.')
        ? mapNestedProps(obj, key, module, fields)
        : mapFlatProps(obj, key, module, key),
    {
      errors() {
        return this.$store._modulesNamespaceMap[normalizeNamespace(module)].state.errors;
      }
    }
  );

const mapNestedProps = (obj, key, module, fields) =>
  ({
    ...obj,
    ...(!obj.hasOwnProperty([key.split('.')[0]]) && {
      [key.split('.')[0]]: function () {
        const objectName = key.split('.')[0];

        const desc = {}
        for (const field of fields.filter(f => f.startsWith(`${objectName}.`))) {
          Object.defineProperty(desc, field.split('.')[1], {
            get: function () {
              return getValue(this.$store._modulesNamespaceMap[normalizeNamespace(module)].state, field.split('.'))
            }.bind(this),
            set: function (value) {
              this.$store.commit(`${module}/FORM_UPDATE_FIELD`, { key: field, value });
            }.bind(this)
          });
        }
        return desc;
      }
    })
  })

const mapFlatProps = (obj, key, module) =>
  ({
    ...obj,
    [key]: {
      get() {
        return this.$store._modulesNamespaceMap[normalizeNamespace(module)].state[key];
      },
      set(value) {
        this.$store.commit(`${module}/FORM_UPDATE_FIELD`, { key, value });
      }
    }
  })

const normalizeNamespace = namespace => namespace + (namespace.charAt(namespace.length - 1) !== '/' ? '/' : '')

export const generateFormHandlerMutations = () =>
  ({
    FORM_UPDATE_FIELD: (state, { key, value }) => updateField(state, { path: key.split('.'), value })
  })

const updateField = (state, { path, value }) =>
  path.reduce((prev, key, index, array) => {
    if (array.length === index + 1) {
      prev[key] = value;
    }

    return prev[key];
  }, state);

const getValue = (o, path) =>
  path.reduce((xs, x) => xs && xs.hasOwnProperty(x) ? xs[x] : null, o)
