import _ from 'lodash'
import vacancyApi from '@/api/vacancy'
import store from '@/store'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    searchQuery: '',
    vacancyTypeId: null,
    sortBy: '',
    sortDesc: false
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    vacancyApi.getAll({...state.filters})
      .then(( {data} ) => commit('SET_LIST', data))
  },
  delete({ commit }, id) {
    return vacancyApi.delete(id)
      .then(() => commit('VACANCY_REMOVED', id))
      .catch((error) => {
        commit('VACANCY_REMOVE_FAILED', { id, errors: error.response.data.errors });
        throw error;
      });

  }
}

const mutations = {
  SET_LIST(state, {items, metadata}) {
    state.list = items;
    state.totalCount = metadata.totalItemCount;
    state.loading = false;
  },
  VACANCY_REMOVED(state, id) {
    store.dispatch('vacancy/fetchList');
    store.dispatch('message/success', 'VACANCY.success')
  },
  VACANCY_REMOVE_FAILED(state, { id, errors }) {
    store.dispatch('message/error', 'VACANCY.removeFailure')
    state.errors = errors;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
  form
}

const watchers = [
  [state => state.vacancy.filters, _.debounce(async () => await store.dispatch('vacancy/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
