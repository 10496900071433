<template>
  <v-subheader v-if='canViewVerticalNavMenuHeader(item) && isTabAvailable(item)'>
    <span
      v-show='!menuIsVerticalNavMini || (menuIsVerticalNavMini && isMouseHovered)'
      class='title-wrapper'
      :class="{'no-style': menuIsVerticalNavMini && !isMouseHovered}"
    >
      <span>{{ t(item.subheader) }}</span>
    </span>

    <v-icon v-show='menuIsVerticalNavMini && !isMouseHovered'>
      {{ icons.mdiMinus }}
    </v-icon>
  </v-subheader>
</template>

<script>
import useAppConfig from '../../../../../app-config/useAppConfig'
import { mdiMinus } from '@mdi/js'
import { inject } from '@vue/composition-api'
import { useUtils } from '../../../../../libs/i18n'
import { useUtils as useAclUtils } from '../../../../../libs/acl'
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', {
      userData: 'data',
    }),
    getUserPermissions() {
      let data = JSON.parse(localStorage.getItem('userData'))
      if (data === undefined || data === null) {
        return this.userData.permissions
      }
      return data.permissions
    },
  },
  methods: {
    isTabAvailable(navItem) {
      if (navItem.children) {
        return navItem.children.some(c => c.permission === undefined || c.permission === null || this.getUserPermissions.indexOf(c.permission) > -1)
      } else {
        return navItem.permission === undefined || navItem.permission === null ? true : this.getUserPermissions.indexOf(navItem.permission) > -1
      }
    },
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')
    const { t } = useUtils()
    const { canViewVerticalNavMenuHeader } = useAclUtils()

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      // i18n
      t,

      // ACL
      canViewVerticalNavMenuHeader,

      // Icons
      icons: {
        mdiMinus,
      },
    }
  },
}
</script>

<style scoped lang='scss'>
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using($material) {
  white-space: nowrap;
  position: relative;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }

  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;
    font-size: 0.75rem;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
</style>
