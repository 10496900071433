import _ from 'lodash'
import reportApi from '@/api/report'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  loading: false,
  filters: {
    year: 0
  }
}

const getters = {
}

const actions = {
  fetchList({ commit }) {
    state.loading = true;
    reportApi.getSalesByYear({...state.filters})
      .then(( {data} ) => commit('SET_LIST', data))
  }
}

const mutations = {
  SET_LIST(state, items) {
    state.list = items;
  },
  CLEAR_ERRORS(state) {
    state.errors = [];
  },
  ...generateFormHandlerMutations(state)
}

const modules = {
}

const watchers = [
  [state => state.salesByYear.filters, _.debounce(async () => await store.dispatch('salesByYear/fetchList'), 250), { immediate: false, deep: true }]
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers
}
