import groupCriteriaApi from '@/api/group-criteria'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  name: '',
  sex: null,
  ageFrom: null,
  ageTo: null,
  dateOfBirth: null,
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return groupCriteriaApi.create(state)
      .then(({ data }) => commit('GROUP_CRITERIA_CREATED', data))
      .catch((error) => {
        commit('GROUP_CRITERIA_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    groupCriteriaApi.getById(id)
      .then(({ data }) => commit('GROUP_CRITERIA_FETCHED', data))
      .catch(err => commit('GROUP_CRITERIA_FETCH_FAILED', err))
  },
  update({ commit }) {
    return groupCriteriaApi.update(state)
      .then(({ data }) => commit('GROUP_CRITERIA_UPDATED', data))
      .catch((error) => {
        commit('GROUP_CRITERIA_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  GROUP_CRITERIA_CREATED() {
    store.dispatch('message/success', 'GROUP_CRITERIA.created')
    store.dispatch('groupCriteria/fetchList')
  },
  GROUP_CRITERIA_CREATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'GROUP_CRITERIA.failedDealer')
  },
  GROUP_CRITERIA_UPDATED() {
    store.dispatch('message/success', 'GROUP_CRITERIA.updated')
    store.dispatch('groupCriteria/fetchList')
  },
  GROUP_CRITERIA_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'GROUP_CRITERIA.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  GROUP_CRITERIA_FETCHED(state, data) {
    Object.assign(state, data)
  },
  GROUP_CRITERIA_FETCH_FAILED() {
    store.dispatch('message/error', 'GROUP_CRITERIA.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
