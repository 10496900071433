import _ from 'lodash'
import menuVersionApi from '@/api/menu-version'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  totalCount: 0,
  errors: [],
  loading: false,
  filters: {
    pageNumber: 1,
    pageSize: 10,
    restaurantId: 0,
    searchQuery: '',
    sortBy: '',
    sortDesc: false,
  },
  tabsList: []
}

const getters = {
  tabs: state => state.tabsList
}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    menuVersionApi.getAllForRestaurant({ ...state.filters })
      .then(({ data }) => commit('SET_LIST', data))
  },
  sync({ commit }, restaurant) {
    store.dispatch('message/load')
    return menuVersionApi.syncForRestaurant(restaurant)
      .then(() => commit('MENU_SYNCED'))
      .catch((error) => {
        commit('MENU_SYNC_FAILED', { errors: error.response.data.errors })
        throw error
      })
  },
  syncAll({ commit }, restaurants) {
    store.dispatch('message/load')
    return menuVersionApi.syncForRestaurant(restaurants)
      .then(() => commit('MENUS_SYNCED'))
      .catch((error) => {
        commit('MENUS_SYNC_FAILED', { errors: error.response.data.errors })
        throw error
      })
  },
  updateVisibility({commit}, menu){
    return menuVersionApi.activate(menu)
      .then(() => commit('MENU_ACTIVATED'))
      .catch((error) => {
        commit('MENU_ACTIVATION_FAILED', error.response.data)
        throw error;
      })
  }
}

const mutations = {
  SET_LIST(state, { items, metadata }) {
    state.list = items
    state.totalCount = metadata.totalItemCount
    state.loading = false
    state.filters.pageNumber = metadata.pageNumber
  },
  MENU_SYNCED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('menuVersion/fetchList')
  },
  MENU_SYNC_FAILED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('message/error', '')
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  MENUS_SYNCED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('menuVersion/fetchList')
  },
  MENUS_SYNC_FAILED(state) {
    store.dispatch('message/loadFinish')
    store.dispatch('message/error', '')
  },
  MENU_ACTIVATION_FAILED(state){
    store.dispatch('message/error', '')
  },
  MENU_ACTIVATED(){
    store.dispatch('menuVersion/fetchList')
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
}

const watchers = [
  [state => state.menuVersion.filters, _.debounce(async () => await store.dispatch('menuVersion/fetchList'), 250), {
    immediate: false,
    deep: true,
  }],
]

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
