import moment from 'moment'

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }
}

export const toDateSting = value => {
  if (isEmpty(value))
    return ''
  return moment(value).format('YYYY-MM-DD')
}

export const hasErrorsForProperty = (errors, property) => {
  return errors[property] !== undefined
}

export const errorMessages = (errors, property) => {
  return errors[property] === undefined ? null : errors[property][0]
}


export const hasPermission = value => {
  if (isEmpty(value))
    return true
  let data = JSON.parse(localStorage.getItem('userData'))
  if (data === undefined || data === null) {
    return true
  } else {
    return data.permissions.includes(value)
  }
}

export const toLocalDate = value => {
  if (isEmpty(value))
    return ''
  return new Date(value).toISOString().substr(0, 10)
}

export const toDateTimeString = value => {
  if (isEmpty(value))
    return ''
  return moment(value).format('YYYY-MM-DD HH:mm:ss')
}

export const toBackDateTimeString = value => {
  if (isEmpty(value))
    return ''
  return moment(value).format('YYYY-MM-DDTHH:mm:ss')
}

export const toTimeString = value => {
  if (isEmpty(value))
    return ''
  return moment(value).format('HH:mm:ss')
}

export const isEmptyOrSpaces = str => {
  return str === undefined || str === null || str.trim() === ''
}
