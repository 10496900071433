import orderStateApi from '@/api/order-state'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  status: '',
  code: 0,
  icon: null,
  isFinal: false,
  isDefault: false,
  errors: {
    errors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return orderStateApi.create(state)
      .then(({ data }) => commit('ORDER_STATE_CREATED', data))
      .catch((error) => {
        commit('ORDER_STATE_CREATE_FAILED', error.response.data)
        throw error
      })
  },
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    orderStateApi.getById(id)
      .then(({ data }) => commit('ORDER_STATE_FETCHED', data))
      .catch(err => commit('ORDER_STATE_FETCH_FAILED', err))
  },
  update({ commit }) {
    return orderStateApi.update(state)
      .then(({ data }) => commit('ORDER_STATE_UPDATED', data))
      .catch((error) => {
        commit('ORDER_STATE_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  ORDER_STATE_CREATED() {
    store.dispatch('orderState/fetchList')
  },
  ORDER_STATE_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'CONTACT.failedCreate')
  },
  ORDER_STATE_UPDATED() {
    store.dispatch('orderState/fetchList')
  },
  ORDER_STATE_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'CONTACT.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  ORDER_STATE_FETCHED(state, data) {
    Object.assign(state, data)
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
