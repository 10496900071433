import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/vacancy-applications`, { params }),
  updateStatus: (vacancyStatus) => axios.put(`/api/admin/v1/vacancy-applications`, vacancyStatus),
  getById: (id) => axios.get(`/api/admin/v1/vacancy-applications/${id}`),
  getFile: async (id) => axios.get(`/api/admin/v1/vacancy-applications/file/${id}`, { responseType: 'blob' }).then((response) => {
    let file = new Blob([response.data], {type: response.headers['content-type']});
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  })
}
