import roleApi from '@/api/role'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  roleId: 0,
  permissions: [],
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    roleApi.getPermissions(id)
      .then(({ data }) => commit('ROLE_PERMISSIONS_FETCHED', {
        roleId : id,
        permissions: data
      }))
      .catch(err => commit('ROLE_PERMISSIONS_FETCH_FAILED', err))
  },
  update({ commit }) {
    return roleApi.updatePermissions(state)
      .then(({ data }) => commit('ROLE_PERMISSIONS_UPDATED', data))
      .catch((error) => {
        commit('ROLE_PERMISSIONS_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  ROLE_PERMISSIONS_UPDATED() {
    store.dispatch('message/success', 'APPLICATION_DESCRIPTION.updated')
    store.dispatch('role/fetchList')
  },
  ROLE_PERMISSIONS_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'APPLICATION_DESCRIPTION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  ROLE_PERMISSIONS_FETCHED(state, data) {
    state.roleId = data.roleId;
    state.permissions = data.permissions;
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
