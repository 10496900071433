import roleApi from '@/api/role'
import form from './form'
import { generateFormHandlerMutations } from '@/helpers/forms'

const state = {
  list: [],
  errors: [],
  loading: false,
}

const getters = {}

const actions = {
  fetchList({ commit }) {
    state.loading = true
    roleApi.getAll()
      .then(({ data }) => commit('SET_LIST', data))
  },
}

const mutations = {
  SET_LIST(state, items) {
    state.list = items
    state.loading = false
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {
  form,
}

const watchers = []

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
