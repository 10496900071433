import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get('/api/admin/v1/counter-parties', { params }),
  getById: (id) => axios.get(`/api/admin/v1/counter-parties/${id}`),
  create: (counterParty) => axios.post(`/api/admin/v1/counter-parties`, counterParty),
  update: (counterParty) => axios.put(`/api/admin/v1/counter-parties`, counterParty),
  getTables: () => axios.get(`/api/admin/v1/counter-parties/tables`),
  getCashes: () => axios.get(`/api/admin/v1/counter-parties/cashes`)
}
