import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/promotions`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/promotions/${id}`),
  create: (promotion, image) => {
    let formData = new FormData()
    formData.append('image', image)
    formData.append('id', promotion.id)
    formData.append('name', promotion.name)
    formData.append('description', promotion.description)
    formData.append('subtitle', promotion.subtitle)
    formData.append('sortIndex', promotion.sortIndex)
    formData.append('isBanner', promotion.isBanner)
    for (let i = 0; i < promotion.restaurants.length; i++) {
      formData.append('restaurants[' + i + ']', promotion.restaurants[i])
    }
    for (let i = 0; i < promotion.contacts.length; i++) {
      formData.append('contacts[' + i + ']', promotion.contacts[i])
    }
    for (let i = 0; i < promotion.translations.length; i++) {
      formData.append('translations[' + i + '].language', promotion.translations[i].language)
      formData.append('translations[' + i + '].name', promotion.translations[i].name)
      formData.append('translations[' + i + '].subtitle', promotion.translations[i].subtitle)
      formData.append('translations[' + i + '].description', promotion.translations[i].description)
    }
    formData.append('dateFrom', new Date(promotion.dateRange.start).toUTCString())
    if (promotion.dateRange.end !==  undefined && promotion.dateRange.end !== null && promotion.dateRange.end !== '') {
      formData.append('dateTo', new Date(promotion.dateRange.end).toUTCString())
    }
    return axios.post(`/api/admin/v1/promotions`, formData)
  },
  update: (promotion, image) => {
    let formData = new FormData()
    formData.append('image', image)
    formData.append('id', promotion.id)
    formData.append('name', promotion.name)
    formData.append('description', promotion.description)
    formData.append('subtitle', promotion.subtitle)
    formData.append('sortIndex', promotion.sortIndex)
    formData.append('isBanner', promotion.isBanner)
    for (let i = 0; i < promotion.restaurants.length; i++) {
      formData.append('restaurants[' + i + ']', promotion.restaurants[i])
    }
    for (let i = 0; i < promotion.contacts.length; i++) {
      formData.append('contacts[' + i + ']', promotion.contacts[i])
    }
    for (let i = 0; i < promotion.translations.length; i++) {
      formData.append('translations[' + i + '].language', promotion.translations[i].language)
      formData.append('translations[' + i + '].name', promotion.translations[i].name)
      formData.append('translations[' + i + '].subtitle', promotion.translations[i].subtitle)
      formData.append('translations[' + i + '].description', promotion.translations[i].description)
    }
    formData.append('dateFrom', new Date(promotion.dateRange.start).toUTCString())
    if (promotion.dateRange.end !==  undefined && promotion.dateRange.end !== null && promotion.dateRange.end !== '') {
      formData.append('dateTo', new Date(promotion.dateRange.end).toUTCString())
    }
    return axios.put(`/api/admin/v1/promotions`, formData)
  },
  delete: (id) => axios.delete(`/api/admin/v1/promotions/${id}`),
}
