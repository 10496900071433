import orderApi from '@/api/order'
import { generateFormHandlerMutations } from '@/helpers/forms'
import store from '@/store'

const state = {
  list: [],
  loading: false,
}

const getters = {}

const actions = {
  fetchList({ commit }, id) {
    state.loading = true
    orderApi.getLogs(id)
      .then(({ data }) => commit('SET_LIST', data))
  },
  suspendWorkFlow({ commit }, id) {
    orderApi.suspendWorkflow(id).then(({ data }) => commit('SUSPEND_WORKFLOW', data))
      .catch(err => commit('SUSPEND_WORKFLOW_FAILED', err))
  },
}

const mutations = {
  SET_LIST(state, items) {
    state.list = items
    state.loading = false
  },
  SUSPEND_WORKFLOW(state, data) {
    store.dispatch('message/success', '')
  },
  SUSPEND_WORKFLOW_FAILED(state, errors) {
    store.dispatch('message/error', '')
  },
  CLEAR_ERRORS(state) {
    state.errors = []
  },
  ...generateFormHandlerMutations(state),
}

const modules = {}

const watchers = []

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules,
  watchers,
}
