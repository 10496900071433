const ERROR = 'error'
const SUCCESS = 'success'
const INFO = 'info'

// 'success', 'info', 'error', 'cyan darken-2'
const defaultState = () => ({
  status: '',
  text: '',
  timeout: 3000,
  display: false,
  displayLoader: false,
})

const state = defaultState()

const getters = {}

const actions = {
  load({commit}){
    commit('SHOW_LOADER')
  },
  loadFinish({commit}){
    commit('DISABLE_LOADER')
  },
  error({ commit }, text) {
    commit('SET_MESSAGE', { status: ERROR, text })
  },
  success({ commit }, text) {
    commit('SET_MESSAGE', { status: SUCCESS, text })
  },
  info({ commit }, text) {
    commit('SET_MESSAGE', { status: INFO, text })
  },
  clear({ commit }) {
    commit('CLEAR_MESSAGE')
  }
}

const mutations = {
  SET_MESSAGE(state, data) {
    state.status = data.status
    state.text = data.text
    state.display = true
  },
  SHOW_LOADER(){
    state.displayLoader = true;
  },
  DISABLE_LOADER(){
    state.displayLoader = false;
  },
  CLEAR_MESSAGE(state) {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
