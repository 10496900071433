import dishApi from '@/api/dish'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  translations: [{
    name: '',
    language: 'ru',
  },{
    name: '',
    language: 'ky',
  },{
    name: '',
    language: 'en',
  }],
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    dishApi.getModifierSchemeTranslationById(id)
      .then(({ data }) => commit('MODIFIER_SCHEME_TRANSLATION_FETCHED', data))
      .catch(err => commit('MODIFIER_SCHEME_FETCH_FAILED', err))
  },
  update({ commit }) {
    return dishApi.updateModifierSchemeTranslation(state)
      .then(({ data }) => commit('MODIFIER_SCHEME_TRANSLATION_UPDATED', data))
      .catch((error) => {
        commit('MODIFIER_SCHEME_TRANSLATION_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  MODIFIER_SCHEME_TRANSLATION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  MODIFIER_SCHEME_TRANSLATION_UPDATED() {
    store.dispatch('message/success', 'GROUP_CRITERIA.updated')
    store.dispatch('modifierSchemeTranslations/fetchList')
  },
  MODIFIER_SCHEME_TRANSLATION_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'GROUP_CRITERIA.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  MODIFIER_SCHEME_TRANSLATION_FETCH_FAILED() {
    store.dispatch('message/error', 'GROUP_CRITERIA.failedFetch')
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
