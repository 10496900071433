import pushNotificationApi from '@/api/push-notification'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  name: '',
  description: '',
  time: '',
  file: null,
  groupCriteriaIds: [],
  customerIds: [],
  errors: {
    errors: [],
    commonErrors: []
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }) {
    return pushNotificationApi.create(state)
      .then(({ data }) => commit('PUSH_NOTIFICATION_CREATED', data))
      .catch((error) => {
        commit('PUSH_NOTIFICATION_CREATE_FAILED', error.response.data);
        throw error;
      })
  },
  fetchById({ commit }, id) {
    commit("FORM_RESET");
    pushNotificationApi.getById(id)
      .then(({ data }) => commit('PUSH_NOTIFICATION_FETCHED', data))
      .catch(err => commit('PUSH_NOTIFICATION_FETCH_FAILED', err))
  },
  update({ commit }) {
    return pushNotificationApi.update(state)
      .then(({ data }) => commit('PUSH_NOTIFICATION_UPDATED', data))
      .catch((error) => {
        commit('PUSH_NOTIFICATION_UPDATE_FAILED', error.response.data)
        throw error;
      })
  },
  async previewPhoneNumbers({commit}, file){
    return (await pushNotificationApi.previewPhones(file)).data
  },
  resetForm({ commit }) {
    commit("FORM_RESET")
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  PUSH_NOTIFICATION_CREATED() {
    store.dispatch('message/success', 'PUSH_NOTIFICATION.created')
    store.dispatch('pushNotification/fetchList')
  },
  PUSH_NOTIFICATION_CREATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'PUSH_NOTIFICATION.failedDealer')
  },
  PUSH_NOTIFICATION_UPDATED() {
    store.dispatch('message/success', 'PUSH_NOTIFICATION.updated')
    store.dispatch('pushNotification/fetchList')
  },
  PUSH_NOTIFICATION_UPDATE_FAILED(state, errors) {
    Object.assign(state.errors, errors)
    store.dispatch('message/error', 'PUSH_NOTIFICATION.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  PUSH_NOTIFICATION_FETCHED(state, data) {
    Object.assign(state, data)
  },
  PUSH_NOTIFICATION_FETCH_FAILED() {
    store.dispatch('message/error', 'PUSH_NOTIFICATION.failedFetch')
  },
  ...generateFormHandlerMutations(state)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
