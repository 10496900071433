import axios from '@/api/requests/axios'

export default {
  getAll: (params) => axios.get(`/api/admin/v1/payment-methods`, { params }),
  getById: (id) => axios.get(`/api/admin/v1/payment-methods/${id}`),
  create: (contact) => axios.post(`/api/admin/v1/payment-methods`, contact),
  update: (contact) => axios.put(`/api/admin/v1/payment-methods`, contact),
  delete: (id) => axios.delete(`/api/admin/v1/payment-methods/${id}`),
  getReasons: () => axios.get('/api/admin/v1/payment-methods/reasons')
}
