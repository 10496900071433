import customDishApi from '@/api/custom-dish'
import store from '@/store'
import { generateFormHandlerMutations } from '@/helpers/forms'

const getDefaultState = () => ({
  id: 0,
  type: '',
  applianceTypes: [
    {
      appliance: 'Single',
      amount: 0,
    },
    {
      appliance: 'Set',
      amount: 0,
    },
  ],
  translations: [
    {
      language: 'ru',
      name: null,
      description: null,
      subtitle: null,
    },
    {
      language: 'ky',
      name: null,
      description: null,
      subtitle: null,
    },
    {
      language: 'en',
      name: null,
      description: null,
      subtitle: null,
    },
  ],
  rkId: 0,
  image: '',
  errors: {
    errors: [],
    commonErrors: [],
  },
})

const state = getDefaultState()

const getters = {}

const actions = {
  create({ commit }, image) {
    return customDishApi.create(state, image)
      .then(({ data }) => commit('PRESENT_DISH_CREATED', data))
      .catch((error) => {
        commit('PRESENT_DISH_CREATE_FAILED', error.response.data)
        throw error
      })
  },
  fetchById({ commit }, id) {
    commit('FORM_RESET')
    customDishApi.getById(id)
      .then(({ data }) => commit('PRESENT_DISH_FETCHED', data))
      .catch(err => commit('PRESENT_DISH_FETCH_FAILED', err))
  },
  update({ commit }, image) {
    return customDishApi.update(state, image)
      .then(({ data }) => commit('PRESENT_DISH_UPDATED', data))
      .catch((error) => {
        commit('PRESENT_DISH_UPDATE_FAILED', error.response.data)
        throw error
      })
  },
  resetForm({ commit }) {
    commit('FORM_RESET')
  },
}

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  },
  PRESENT_DISH_CREATED() {
    store.dispatch('message/success', 'PRESENT_DISH.created')
    store.dispatch('customDishes/fetchList')
  },
  PRESENT_DISH_CREATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'PRESENT_DISH.failedDealer')
  },
  PRESENT_DISH_UPDATED() {
    store.dispatch('message/success', 'PRESENT_DISH.updated')
    store.dispatch('customDishes/fetchList')
  },
  PRESENT_DISH_UPDATE_FAILED(state, errors) {
    state.errors.errors = errors.validationErrors
    store.dispatch('message/error', 'PRESENT_DISH.failedUpdate')
  },
  FORM_RESET(state) {
    Object.assign(state, getDefaultState())
  },
  PRESENT_DISH_FETCHED(state, data) {
    Object.assign(state, data)
  },
  PRESENT_DISH_FETCH_FAILED() {
    store.dispatch('message/error', 'PRESENT_DISH.failedFetch')
  },
  ...generateFormHandlerMutations(state),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
